import API_URL from "./config";
import Construct from "./construct";

class EtatBadge extends Construct {
  async getEtatBadge() {
    try {
      const response = await this._request.get(`${API_URL}/etat_badges`);
      const { data } = response;
      const result = data["hydra:member"];
      return result;
    } catch (err) {
      throw new Error(err);
    }
  }

  async editEtatBadge(id, denomination) {
    try {
      const response = await this._request.put(`${API_URL}/etat_badges/${id}`, {
        denomination: denomination,
      });
      return response;
    } catch (err) {
      throw new Error(err);
    }
  }
}
const etatBadge = new EtatBadge();
export default etatBadge;
