import { useEffect, useState } from "react";
import { ShadowBox } from "../../components/BoxShadow";
import { Grid, Typography, TextField, Box } from "@mui/material";
import { Styles } from "../../styles/style";
import { ComponentButton } from "../../components/Button";
import clients from "../../actions/client";
import users from "../../actions/users";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

export const AddClient = ()=> {
    const location = useLocation();
    const { from } = location?.state || {};
    const navigate = useNavigate();
    const [client, setClient] = useState(
        {
            nom: "",
            telContact: "",
            emailClient: ""
        }
    );
    const [user, setUser] = useState(from ? 
        {
            email: from.email,
            password: "",
            nomagent: from.nomagent || "",
            prenomagent: from.prenomagent || "",
            matriculeagent: from.matriculeagent || "",
            telagent: from.telagent || "",
            idclientAdmin: from.idclientAdmin || null,
            roles: from.roles
        }
        :
        {
            email: "",
            password: "",
            nomagent: "",
            prenomagent: "",
            matriculeagent: "",
            telagent: "",
            idclientAdmin: "",
            roles: ["ROLE_USER", "ROLE_ADMIN"]
        }
    )
    const handleUserChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        })
    }
    const handleSubmit = async (e)=> {
        e.preventDefault();
        try {
            if (from) {
                const response = await users.editUsers(user, from.id);
                if (response.status === 200)
                    navigate('/');
            }
            else {
                const clientAdminId = await clients.setClient(client)
                const jsonUser = {
                    ...user,
                    idclientAdmin: clientAdminId
                }
                const response = await users.setUsers(jsonUser);
                if (response.status === 200)
                    navigate('/');
            }
        } catch (err) {
            alert("Un erreur est survenu ou cette email exist déjà");   
        }
    }

    useEffect(()=> {
        setClient(prev => {
            return {
                ...prev,
                nom: user.nomagent,
                telContact: user.telagent,
                emailClient: user.email
            }
        })
    }, [user])
    return (
        <ShadowBox>
            <Box sx={{
                marginTop: 2
            }}>
                <Link to="/" style={{
                    color: Styles.color.dark
                }}>
                    <ArrowBack />
                </Link>
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2} sx={{
                    marginTop: 1
                }}>
                    <Grid item xs={6}>
                        <Grid container direction="row">
                            <Typography style={ Styles.typography }>
                                Nom
                            </Typography>
                            <TextField placeholder="..."  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="nomagent"
                                value={user.nomagent}
                                onChange={handleUserChange}
                                required
                            />
                            <Typography style={ Styles.typography }>
                                Prénom
                            </Typography>
                            <TextField placeholder="..."  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="prenomagent"
                                value={user.prenomagent}
                                onChange={handleUserChange}
                            />
                             <Typography style={ Styles.typography }>
                                Matricule
                            </Typography>
                            <TextField placeholder="..."  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="matriculeagent"
                                value={user.matriculeagent}
                                onChange={handleUserChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction="row">
                            <Typography style={ Styles.typography }>
                                Téléphone
                            </Typography>
                            <TextField placeholder="..."  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="telagent"
                                value={user.telagent}
                                onChange={handleUserChange}
                            />
                            <Typography style={ Styles.typography }>
                                Email
                            </Typography>
                            <TextField placeholder="..."  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                name="email"
                                type="email"
                                value={user.email}
                                onChange={handleUserChange}
                                required
                            />
                            <Typography style={ Styles.typography }>
                                Mot de passe
                            </Typography>
                            <TextField placeholder="..."  sx={{ width: '100%' }}
                                InputProps={{
                                    style: Styles.propsTextField
                                }}
                                type="password"
                                name="password"
                                value={user.password}
                                onChange={handleUserChange}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid> 
                <Grid 
                    container 
                    spacing={0} 
                    justifyContent="flex-end"
                    sx={{
                        margin: "2.5rem 0 1.5rem 0"
                    }}
                >
                    <Grid item>
                        <ComponentButton backgroundColor={ Styles.color.red }>Valider</ComponentButton>
                    </Grid>
                </Grid>  
            </form>
        </ShadowBox>
    )
}