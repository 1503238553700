import {
  Grid,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr";
import { ShadowBox } from "../../components/BoxShadow";
import { Styles } from "../../styles/style";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ComponentButton } from "../../components/Button";
import { StatusHead } from "../../components/AddAdminHeader";
import { Link } from "react-router-dom";
import { slugs } from "../../actions/data";
import enterprise from "../../actions/enterprise";
import category from "../../actions/category";
import visitor from "../../actions/visitors";
import visitedPersonne from "../../actions/visitedPersonne";
import object from "../../actions/object";
import badges from "../../actions/badges";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SameTop } from "../../components/SameTop";
import { AddModal } from "../../components/AddModal";
import modalSubmited from "../../actions/modalSubmited";
import uuid from "react-uuid";
import { useVisitorContext } from "../../actions/visitorContext";

export const AddList = () => {
  const location = useLocation();
  const { handleVisitorReset } = useVisitorContext();
  const { slug } = useParams();
  const { from } = location?.state || {};
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isSubmited, setIsSubmited] = useState(1);
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [enterpriseOption, setEnterpriseOption] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryOption, setCategoryOption] = useState("");
  const [chekedStatusVisitor, setCheckedStatusVisitor] = useState(true);
  const [chekedStatusVisited, setCheckedStatusVisited] = useState(true);
  const [chekedStatusObject, setCheckedStatusObject] = useState(true);
  const [objectInput, setObjectInput] = useState(
    from
      ? {
          ...from,
        }
      : {
          nomobjet: "",
          estActif: true,
        }
  );
  const [visitorInput, setVisitorInput] = useState(
    from
      ? {
          ...from,
          cinDate: null,
          cinDateEcheance: null,
          formationSecurite: "",
          dateFormation: null,
          dateFormationEcheance: null,
        }
      : {
          nom: "",
          prenom: "",
          email: "",
          cin: "",
          cinDate: null,
          cinDateEcheance: null,
          formationSecurite: "",
          dateFormation: null,
          dateFormationEcheance: null,
          estActif: true,
          tel: "",
          remarque: "",
          categorieVisiteur: "",
          societe: "",
        }
  );
  const [visited, setVisited] = useState(
    from
      ? {
          ...from,
          prenom: from?.prenom || "",
          email: from?.email || "",
          tel: from?.tel || "",
        }
      : {
          nom: "",
          prenom: "",
          email: "",
          tel: "",
          estActif: true,
        }
  );
  const [enterpriseCategory, setEnterpriseCategory] = useState(
    from ? from?.libelle || from?.denomination : ""
  );
  const [badge, setBadge] = useState(
    from
      ? {
          denomination: from?.denomination,
          status: from?.etatbadge,
        }
      : {
          denomination: "",
          status: true,
        }
  );

  const handleObjectChange = (e) => {
    setObjectInput({
      ...objectInput,
      [e.target.name]: e.target.value,
    });
  };
  const handleVisitorChange = (e) => {
    setVisitorInput({
      ...visitorInput,
      [e.target.name]: e.target.value,
    });
  };
  const handleVisitedChange = (e) => {
    setVisited({
      ...visited,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeCINDate = (date) => {
    setVisitorInput({
      ...visitorInput,
      cinDateEcheance: date.$d.toISOString(),
    });
  };
  const handleChangeDateFormation = (date) => {
    setVisitorInput({
      ...visitorInput,
      dateFormation: date.$d.toISOString(),
    });
  };
  const handleChangeEcheanceFormation = (date) => {
    setVisitorInput({
      ...visitorInput,
      dateFormationEcheance: date.$d.toISOString(),
    });
  };
  const handleChangeChekedVisitor = () => {
    setCheckedStatusVisitor(!chekedStatusVisitor);
  };
  const handleChangeChekedVisited = () => {
    setCheckedStatusVisited(!chekedStatusVisited);
  };
  const handleChangeChekedObject = () => {
    setCheckedStatusObject(!chekedStatusObject);
  };
  const handleChangeEnterpriseOption = (e) => {
    setEnterpriseOption(e.target.value);
  };
  const handleChangeCategoryOption = (e) => {
    setCategoryOption(e.target.value);
  };
  const handleChangeBadge = (e) => {
    setBadge((prev) => {
      return {
        ...prev,
        denomination: e.target.value,
      };
    });
  };
  const handleChangeCheckedBadge = () => {
    setBadge((prev) => {
      return {
        ...prev,
        status: !prev.status,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (slug) {
      case "Visitor": {
        const updateVisitor = async () => {
          try {
            handleVisitorReset();
            if (from) await visitor.editVisitor(visitorInput, from.idvisiteur);
            else await visitor.setVisitor(visitorInput);
            navigate(`/admin/${slug}`);
          } catch (err) {
            setError("Visiteur non enregistré!");
          }
        };
        updateVisitor();
        break;
      }
      case "Visited": {
        const updateVisited = async () => {
          try {
            if (from)
              await visitedPersonne.editVisitedPersonne(
                visited,
                from.idpersonnelvisite
              );
            else await visitedPersonne.setVisitedPersonne(visited);
            navigate(`/admin/${slug}`);
          } catch (err) {
            setError("Visité non enregistré!");
          }
        };
        updateVisited();
        break;
      }
      case "Enterprise": {
        const updateEnterprise = async () => {
          try {
            if (from)
              await enterprise.editEnterprise(
                enterpriseCategory,
                from.idsociete
              );
            else await enterprise.setEnterprise(enterpriseCategory);
            navigate(`/admin/${slug}`);
          } catch (err) {
            setError("Entreprise non enregistré!");
          }
        };
        updateEnterprise();
        break;
      }
      case "Category": {
        const updateCategory = async () => {
          try {
            if (from)
              await category.editCategory(
                enterpriseCategory,
                from.idcategorievisiteur
              );
            else await category.setCategory(enterpriseCategory);
            navigate(`/admin/${slug}`);
          } catch (err) {
            setError("Catégorie non enregistré!");
          }
        };
        updateCategory();
        break;
      }
      case "Object": {
        const updateObject = async () => {
          try {
            if (from) await object.editObject(objectInput, from.idtypeobjet);
            else await object.setObject(objectInput);
            navigate(`/admin/${slug}`);
          } catch (err) {
            setError("Objet non enregistré!");
          }
        };
        updateObject();
        break;
      }
      case "Badge": {
        const updateBadge = async () => {
          try {
            if (from) await badges.editBadge(badge, from.idbadge);
            else await badges.createBadges(badge);
            navigate(`/admin/${slug}`);
          } catch (err) {
            setError("Badge non enregistré!");
          }
        };
        updateBadge();
        break;
      }
      default:
        return;
    }
  };

  const handleIsSubmited = () => {
    setIsSubmited((prev) => prev * -1);
  };

  useEffect(() => {
    setVisited((visited) => {
      return {
        ...visited,
        estActif: chekedStatusVisited,
      };
    });
  }, [chekedStatusVisited]);

  useEffect(() => {
    setObjectInput((objectInput) => {
      return {
        ...objectInput,
        estActif: chekedStatusObject,
      };
    });
  }, [chekedStatusObject]);

  useEffect(() => {
    setVisitorInput((visitorInput) => {
      return {
        ...visitorInput,
        societe: enterpriseOption,
        categorieVisiteur: categoryOption,
        estActif: chekedStatusVisitor,
      };
    });
  }, [enterpriseOption, categoryOption, chekedStatusVisitor]);

  useEffect(() => {
    const getEnterprises = async () => {
      const data = await enterprise.getEnterprise();
      setEnterpriseOptions(data);
      const enterpriseId = from ? from?.societe?.idsociete : "";
      setEnterpriseOption(enterpriseId);
    };
    const getCategories = async () => {
      const data = await category.getCategory();
      setCategoryOptions(data);
      const categoryId = from
        ? from?.categorieVisiteur?.idcategorievisiteur
        : "";
      setCategoryOption(categoryId);
    };
    getEnterprises();
    getCategories();
  }, [isSubmited, from]);

  return (
    <form onSubmit={handleSubmit}>
      <ShadowBox>
        {error && (
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: "0.8rem",
              color: Styles.color.red,
              textAlign: "center",
              paddingTop: 1,
              paddingBottom: 0,
            }}
          >
            {error}
          </Typography>
        )}
        {slug === "Visitor" && (
          <>
            <SameTop>
              <StatusHead
                slug={slugs[slug]}
                handleChangeCheked={handleChangeChekedVisitor}
                chekedStatus={chekedStatusVisitor}
              />
            </SameTop>
            <hr style={{ color: Styles.color.blue }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>Nom *</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="nom"
                    value={visitorInput.nom}
                    onChange={handleVisitorChange}
                    required
                  />
                  <Typography style={Styles.typography}>Téléphone *</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="tel"
                    value={visitorInput.tel}
                    onChange={handleVisitorChange}
                    required
                  />
                  <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-end"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Grid item xs>
                      <Typography style={Styles.typography}>
                        Société *
                      </Typography>
                      <Select
                        sx={{
                          width: "100%",
                          height: "2.5rem",
                        }}
                        style={Styles.inputDefaultTypo}
                        value={enterpriseOption}
                        onChange={handleChangeEnterpriseOption}
                        required
                      >
                        {enterpriseOptions.map((enterprise) => (
                          <MenuItem key={uuid()} value={enterprise.idsociete}>
                            {enterprise.denomination}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <AddModal
                      title={"Société"}
                      handleSubmit={modalSubmited.handleAddEnterprise}
                      handleIsSubmited={handleIsSubmited}
                    />
                  </Grid>
                  <Typography style={Styles.typography}>CIN *</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="cin"
                    value={visitorInput.cin}
                    onChange={handleVisitorChange}
                    required
                  />
                  <Typography style={Styles.typography}>
                    Formation sécurité
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr"
                  >
                    <DatePicker
                      label={
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: "inherit",
                          }}
                        >
                          ...
                        </Typography>
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "11rem",
                            "& .MuiOutlinedInput-input":
                              Styles.inputDefaultTypo,
                          },
                          style: { width: "100%" },
                        },
                      }}
                      onChange={handleChangeDateFormation}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>Prénom *</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="prenom"
                    value={visitorInput.prenom}
                    onChange={handleVisitorChange}
                    required
                  />
                  <Typography style={Styles.typography}>Email</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                      type: "email",
                    }}
                    name="email"
                    value={visitorInput.email}
                    onChange={handleVisitorChange}
                  />
                  <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                    alignItems="flex-end"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Grid item xs>
                      <Typography style={Styles.typography}>
                        Catégorie *
                      </Typography>
                      <Select
                        sx={{
                          width: "100%",
                          height: "2.5rem",
                        }}
                        style={Styles.inputDefaultTypo}
                        value={categoryOption}
                        onChange={handleChangeCategoryOption}
                        required
                      >
                        {categoryOptions.map((category) => (
                          <MenuItem
                            key={uuid()}
                            value={category.idcategorievisiteur}
                          >
                            {category.libelle}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <AddModal
                      title={"Catégorie"}
                      handleSubmit={modalSubmited.handleAddCategory}
                      handleIsSubmited={handleIsSubmited}
                    />
                  </Grid>
                  <Typography style={Styles.typography}>
                    Validité CIN
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr"
                  >
                    <DatePicker
                      label={
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: "inherit",
                          }}
                        >
                          ...
                        </Typography>
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            width: "11rem",
                            "& .MuiOutlinedInput-input":
                              Styles.inputDefaultTypo,
                          },
                          style: { width: "100%" },
                        },
                      }}
                      onChange={handleChangeCINDate}
                    />
                  </LocalizationProvider>
                  <Typography style={Styles.typography}>
                    Echéance formation sécurité
                  </Typography>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="fr"
                  >
                    <DatePicker
                      label={
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: "inherit",
                          }}
                        >
                          ...
                        </Typography>
                      }
                      slotProps={{
                        textField: {
                          size: "small",
                          sx: {
                            "& .MuiOutlinedInput-input":
                              Styles.inputDefaultTypo,
                          },
                          style: { width: "100%" },
                        },
                      }}
                      onChange={handleChangeEcheanceFormation}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>Remarque</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                      sx: {
                        height: "107px !important",
                        alignItems: "flex-start",
                      },
                    }}
                    name="remarque"
                    value={visitorInput.remarque}
                    onChange={handleVisitorChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {slug === "Visited" && (
          <>
            <SameTop>
              <StatusHead
                slug={slugs[slug]}
                handleChangeCheked={handleChangeChekedVisited}
                chekedStatus={chekedStatusVisited}
              />
            </SameTop>
            <hr style={{ color: Styles.color.blue }} />
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>Nom *</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="nom"
                    value={visited.nom}
                    onChange={handleVisitedChange}
                    required
                  />
                  <Typography style={Styles.typography}>Téléphone *</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="tel"
                    value={visited.tel}
                    onChange={handleVisitedChange}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>Prénom</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="prenom"
                    value={visited.prenom}
                    onChange={handleVisitedChange}
                  />
                  <Typography style={Styles.typography}>Email</Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                      type: "email",
                    }}
                    name="email"
                    value={visited.email}
                    onChange={handleVisitedChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {(slug === "Enterprise" || slug === "Category") && (
          <>
            <SameTop>
              <Grid item sx={Styles.fontTitle}>
                {slugs[slug]}
              </Grid>
            </SameTop>
            <hr style={{ color: Styles.color.blue }} />
            <Grid container spacing={1}>
              <Grid item xs>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>
                    Dénomination *
                  </Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    value={enterpriseCategory}
                    onChange={(e) => setEnterpriseCategory(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {slug === "Object" && (
          <>
            <SameTop>
              <StatusHead
                slug={slugs[slug]}
                handleChangeCheked={handleChangeChekedObject}
                chekedStatus={chekedStatusObject}
              />
            </SameTop>
            <hr style={{ color: Styles.color.blue }} />
            <Grid container spacing={1}>
              <Grid item xs>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>
                    Dénomination *
                  </Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    name="nomobjet"
                    value={objectInput.nomobjet}
                    onChange={handleObjectChange}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {slug === "Badge" && (
          <>
            <SameTop>
              <StatusHead
                slug={slugs[slug]}
                handleChangeCheked={handleChangeCheckedBadge}
                chekedStatus={badge.status}
              />
            </SameTop>
            <hr style={{ color: Styles.color.blue }} />
            <Grid container spacing={1}>
              <Grid item xs>
                <Grid container direction="row">
                  <Typography style={Styles.typography}>
                    Dénomination *
                  </Typography>
                  <TextField
                    placeholder="..."
                    sx={{ width: "100%" }}
                    InputProps={{
                      style: Styles.propsTextField,
                    }}
                    value={badge.denomination}
                    onChange={handleChangeBadge}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </ShadowBox>
      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{
          padding: "0 2.5rem 2.5rem 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              md: "flex-end",
            },
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              marginRight: 2,
            }}
          >
            <Link to={`/admin/${slug}`}>
              <ComponentButton backgroundColor={"#8D9496"} type={"button"}>
                Annuler
              </ComponentButton>
            </Link>
          </Box>
          <Box>
            <ComponentButton backgroundColor={Styles.color.red}>
              Valider
            </ComponentButton>
          </Box>
        </Box>
      </Grid>
    </form>
  );
};
