import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { Styles } from '../styles/style';
import { LoadingData, NullData } from './Loading';
import { Delete, Edit } from './ButtonAction';
import { StyledTableCell, StyledTableRow } from '../styles/style';

const cellTableHead = (content, key)=> {
    return (
        <StyledTableCell key={key} align='left'>
            <Box sx={{
                color: Styles.color.darkGrey,
                textTransform: 'capitalize',
                padding: 1
            }} style={Styles.tableHeaderFont}>
                { content }
            </Box>
        </StyledTableCell>
    );
}

export default function AdminTable({ 
    openFullMenu, 
    dataHeader, 
    isOnload, 
    dataBody, 
    menu, 
    slug 
}) {

    const maxWidth = openFullMenu && window.innerWidth > 1200 ? 
    window.innerWidth - 420 : window.innerWidth;

    return (
        <Box style={{
            maxWidth: `${maxWidth}px`
        }}>
            <TableContainer 
                component={Paper}
                sx={{
                    boxShadow: "none"
                }}
            >
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {dataHeader.map((content, key) => cellTableHead(content, key))}
                            <StyledTableCell align='right' style={Styles.tableHeaderFont}>
                                <Box sx={{
                                    marginRight: 3,
                                    padding: 1
                                }}>
                                    Action
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            dataBody.length === 0 ?
                                <NullData length={dataHeader.length} />
                            :
                            slug === "Visitor" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        menu[0]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.nom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[1]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.prenom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[2]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.societe?.denomination || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[3]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.email || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[4]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.tel || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[5]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.cin || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[6]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.categorieVisiteur?.libelle || "...." }
                                        </StyledTableCell>
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit link={`/add/${slug}`} state={value}/>
                                            <Delete link={`/delete/${slug}`} state={value} />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            slug === "Visited" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        menu[0]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.nom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[1]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.prenom || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[2]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.email || "...." }
                                        </StyledTableCell>
                                    }
                                    {
                                        menu[3]?.status &&
                                        <StyledTableCell 
                                            style={Styles.fontTable}>
                                            { value?.tel || "...." }
                                        </StyledTableCell>
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit link={`/add/${slug}`} state={value}/>
                                            <Delete link={`/delete/${slug}`} state={value} />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            (slug === "Enterprise" || slug === "Category") &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        (menu[0]?.status && slug === "Enterprise") &&
                                        dataHeader.map((_, key) => 
                                            <StyledTableCell key={key} style={Styles.fontTable}>
                                                { value?.denomination ? value.denomination : '....' }
                                            </StyledTableCell>
                                        )
                                    }
                                    {
                                        (menu[0]?.status && slug === "Category") &&
                                        dataHeader.map((_, key) => 
                                            <StyledTableCell key={key} style={Styles.fontTable}>
                                                { value?.libelle ? value.libelle : '....' }
                                            </StyledTableCell>
                                        )
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit link={`/add/${slug}`} state={value}/>
                                            <Delete link={`/delete/${slug}`} state={value} />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            slug === "Object" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                    {
                                        menu[0]?.status &&
                                        dataHeader.map((_, key) => 
                                            <StyledTableCell key={key} style={Styles.fontTable}>
                                                { value?.nomobjet ? value.nomobjet : '....' }
                                            </StyledTableCell>
                                        )
                                    }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit link={`/add/${slug}`} state={value}/>
                                            <Delete link={`/delete/${slug}`} state={value} />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            slug === "Badge" &&
                            dataBody.map((value, index) => (
                                <StyledTableRow key={index}>
                                        {
                                            menu[0]?.status &&
                                            <StyledTableCell style={Styles.fontTable}>
                                                { value?.denomination || '....' }
                                            </StyledTableCell>
                                        }
                                        {
                                            menu[1]?.status &&
                                            <StyledTableCell style={Styles.fontTable}>
                                                { value?.etatbadge ? "Pas encore utilisé" : "En cours d'utilisation" }
                                            </StyledTableCell>
                                        }
                                    <StyledTableCell align='right'>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <Edit link={`/add/${slug}`} state={value}/>
                                            <Delete link={`/delete/${slug}`} state={value} />
                                        </Box>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}