import { ShadowBox } from "../components/BoxShadow";
import { Styles } from "../styles/style";
import { Grid, Box, Typography, InputBase } from "@mui/material";
import { useEffect, useState } from "react";
import { InputSelect } from "../components/Select";
import { Search } from "@mui/icons-material";
import { CustomizePagination } from "../components/Pagination";
import ProfilsTable from "../components/ProfilsTable";
import profils from "../actions/profils";
import { CustomModal } from "../components/AddModal";

const profilFilter = (data, input) => {
  const searchInput = input.toLowerCase();
  const results = data.filter((data) => {
    const denomination = data?.denomination
      ? data.denomination.toLowerCase()
      : "";
    return denomination.indexOf(searchInput) > -1;
  });
  return results;
};

export const Profil = ({ openFullMenu }) => {
  const [pageNumbers, setPageNumbers] = useState(null);
  const [linesNumbers, setLinesNumbers] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [checkPagination, setCheckPagination] = useState(10);
  const [isOnSearch, setIsOnSearch] = useState("");
  const [isOnload, setIsOnload] = useState(true);
  const [isSubmitProfil, setIsSubmitProfil] = useState(1);
  const [dataBody, setDataBody] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [status, setStatus] = useState(true);

  const handleSubmitProfil = async (denomination) => {
    try {
      const response = await profils.setProfil({
        denomination,
      });

      if (response !== true) throw new Error("Un erreur est survenu");
    } catch (err) {
      alert(err);
    }
  };

  const handleIsSubmitProfil = () => {
    setIsSubmitProfil((prev) => prev * -1);
  };

  const handlePagesNumbers = () => {
    const pages =
      isOnSearch && status !== null
        ? Math.floor((dataFilter.length - 1) / checkPagination)
        : Math.floor((dataBody.length - 1) / checkPagination);

    setPageNumbers(pages);
  };

  const handlePaginationActive = (activePage) => {
    setActivePage(activePage);
  };

  const handlePaginationChecked = (checkPagination) => {
    setCheckPagination(checkPagination);
  };

  const handleStatus = (e) => {
    const { value } = e.target;
    if (value === "Actif") return setStatus(true);
    if (value === "Inactif") return setStatus(false);
    setStatus("Tous");
  };

  useEffect(() => {
    setLinesNumbers(dataFilter.length);
  }, [activePage, dataFilter]);

  useEffect(() => {
    const filtered = async () => {
      const data = await profils.isDataActif(dataBody, status);
      setDataFilter(profilFilter(data, isOnSearch));
    };
    filtered();
  }, [isOnSearch, dataBody, status]);

  useEffect(() => {
    const callUsersGetter = async () => {
      setIsOnload(true);
      const data = await profils.getProfils();
      setDataBody(data);
      setIsOnload(false);
    };
    callUsersGetter();
  }, [isSubmitProfil]);
  return (
    <>
      <ShadowBox>
        <Grid
          container
          rowSpacing={1}
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
          alignItems="center"
          sx={{
            minHeight: 80,
          }}
        >
          <Grid
            item
            sx={Styles.fontTitle}
            style={{
              color: Styles.color.blue,
            }}
          >
            Liste des profiles
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 1,
              marginBottom: 1,
              flexWrap: {
                xs: "wrap",
                md: "nowrap",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "70px",
                  md: "auto",
                },
                marginRight: {
                  xs: "20px",
                  lg: 1,
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontSize: "1rem",
                  fontWeight: 400,
                  letterSpacing: "0.5px",
                }}
              >
                Status
              </Typography>
            </Box>
            <Box>
              <InputSelect
                options={["Actif", "Inactif", "Tous"]}
                handleChangeOption={handleStatus}
              />
            </Box>
          </Box>
        </Grid>
        <hr style={{ color: Styles.color.blue, marginBottom: 20 }} />
        <Grid
          container
          rowSpacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            marginBottom: 3,
          }}
        >
          <Grid item>
            <CustomModal
              title={"profil"}
              handleSubmit={handleSubmitProfil}
              handleIsSubmited={handleIsSubmitProfil}
            />
          </Grid>
          <Grid item>
            <Box
              style={Styles.boxModel}
              sx={{
                minWidth: {
                  xs: 170,
                  md: "24.688rem",
                },
                minHeight: {
                  xs: 20,
                  md: "2.8125rem",
                },
                display: "flex",
                alignItems: "center",
                border: `1.5px solid ${Styles.color.grey}`,
              }}
            >
              <Search
                sx={{
                  marginLeft: 1,
                  color: Styles.color.grey,
                }}
              />
              <InputBase
                placeholder="Recherche..."
                sx={{
                  fontFamily: "Montserrat",
                  border: `1px solid transparent`,
                  width: "100%",
                  padding: "0 10px",
                  color: Styles.color.grey,
                }}
                value={isOnSearch}
                onChange={(e) => setIsOnSearch(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={0} justifyContent="center">
          <Grid item xs>
            <ProfilsTable
              isOnload={isOnload}
              openFullMenu={openFullMenu}
              dataHeader={["Dénomination"]}
              dataBody={dataFilter}
              handleIsSubmitProfil={handleIsSubmitProfil}
            />
          </Grid>
        </Grid>
      </ShadowBox>
      <Box
        sx={{
          padding: 5,
          paddingTop: 0,
        }}
      >
        <CustomizePagination
          linesNumbers={linesNumbers}
          pageNumbers={pageNumbers}
          handlePagesNumbers={handlePagesNumbers}
          handlePaginationChecked={handlePaginationChecked}
          handlePaginationActive={handlePaginationActive}
        />
      </Box>
    </>
  );
};
