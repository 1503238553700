import React, { useEffect, useState } from "react";
import { 
    Grid, 
    Box,
    TextField, 
    InputAdornment,
    ListItemButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography
} from "@mui/material"
import { Styles } from "../styles/style";
import { ButtonEffect } from "./ButtonIcon";
import { ExpandLess, ExpandMore, Logout } from "@mui/icons-material";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import users from "../actions/users";
import { store } from "../actions/store";
import visitor from "../actions/visitors";
import visite from "../actions/visite";
import { useLocation } from "react-router-dom";
import { useVisitorContext } from "../actions/visitorContext";
import { dateFormat } from "../actions/dateFormated";

export const Header = ({ 
    openFullMenu,
    handleClickFullMenu, 
    setOpenSetting,
    setActive,
    setModalForm,
    onSearch,
    setOnsearch,
    handleOpenForm
})=> {
    const location = useLocation();
    const { handleIsVisitorOnSearch } = useVisitorContext();
    const handleSearch = location.pathname === "/" ? handleIsVisitorOnSearch : null;
    const [visitorResults, setVisitorResults] = useState([]);
    const [user, setUser] = useState({});
    const signOut = useSignOut();
    const [anchorLogout, setAnchorLogout] = React.useState(null);
    const openLogout = Boolean(anchorLogout);
    const handleOpenLogout = (ev) => {
        setAnchorLogout(ev.currentTarget);
    };
    const handleCloseLogout = () => {
        setAnchorLogout(null);
    };
    const logOut = ()=> {
        signOut();
        window.location.reload();
    }
    useEffect(()=> {
        const visitorFilter = async ()=> {
            if (!onSearch) return;
            const visitors = await visitor.filterVisitor(onSearch);
            const visites  = await visite.getVisite();
            const filterVisites = visites.filter(el => {
                const now = dateFormat(new Date());
                const enter = dateFormat(new Date(el?.dateEntre));
                const getOut = el?.dateSortie;
                return now === enter && !getOut;
            })
            const filterVisitors = visitors.filter(visitor => {
                let isExist = -1;
                filterVisites.forEach(visite => {
                    if (visitor.idvisiteur === visite?.visiteur.idvisiteur)
                        isExist ++;
                });
                return isExist === -1;
            })
            setVisitorResults(filterVisitors);
        }
        visitorFilter();
    }, [onSearch])
    useEffect(()=> {
        const getUsersByEmail = async ()=> {
            try {
                const email = store.tokenObject.authValue.userState.email;
                const { data } = await users.getUserByEmail(email);
                store.tokenObject.authValue.userState = { data };
                setUser(data);
            } catch (err) {
                console.error(err);
            }
        } 
        getUsersByEmail();
    }, []);
    return (
        <Box sx={{
            position: "fixed",
            backgroundColor: "#fff",
            width: openFullMenu && window.innerWidth > 1200 ? window.innerWidth - 267 : "100%",
            zIndex: 2
        }}>
            <Grid 
                container 
                spacing={ 1 } 
                sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0px 4px 20px 0px #0000001A",
                    padding: 0.5
                }}
            >
                <Grid item className="menuList"
                    sx={{
                        cursor: "pointer"
                    }}
                    onClick={handleClickFullMenu}
                    xs={0}
                >
                    <ButtonEffect>
                        <img src={'/assets/icons/full-menu.png'} alt="Menu" />
                    </ButtonEffect>
                </Grid>
                <Grid item xs={2} sm={1.5} md={1} lg={0.5}
                    sx={{
                        backgroundColor: "#0FBA2A",
                        borderRadius: 1,
                        padding: "0.5rem 0",
                        cursor: "pointer",
                        marginTop: 1
                    }} onClick={handleOpenForm}
                    title="Entrée"
                >
                    <Grid 
                        container 
                        justifyContent="center" 
                        alignItems="center" 
                        sx={{
                            height: "100%"
                        }}
                    >
                        <Grid item>
                            <img src={'/assets/icons/enter-white.png'} alt="Enter" width={25} />
                        </Grid>
                    </Grid> 
                </Grid>
                <Grid item xs sx={{
                    position: "relative"
                }}>
                    <TextField
                        id="search"
                        sx={{ width: '99%' }}
                        InputProps={{
                            sx: {
                                backgroundColor: Styles.color.primary,
                                height: '2.813rem'
                            },
                            style: Styles.inputDefaultTypo ,
                            startAdornment:
                                <InputAdornment position="start">
                                    <img src={'/assets/icons/search.png'} alt="Search" />
                                </InputAdornment>
                        }}
                        value={onSearch}
                        onChange={e => setOnsearch(e.target.value)}
                    />
                    {
                        (onSearch && visitorResults.length !==0) &&
                        <Box sx={{
                            position: "absolute",
                            backgroundColor: "#fff",
                            width: "95.5%",
                            maxHeight: 300,
                            overflowY: "auto",
                            zIndex: 1,
                            padding: 2,
                            boxShadow: "0px 4px 20px 0px #0000001A"
                        }}>
                            {
                                visitorResults.map(visitor => {
                                    return (
                                        <Box
                                            key={visitor.idvisiteur} 
                                        >
                                            <MenuItem 
                                                sx={Styles.fontTable}
                                                style={{
                                                    backgroundColor: Styles.color.primary,
                                                    borderRadius: 2
                                                }}
                                                onClick={()=> {
                                                    setOnsearch("");
                                                    if (!handleSearch) return;
                                                    handleSearch(visitor)
                                                    setModalForm(()=> true)
                                                }}
                                            >
                                                {visitor.prenom} {visitor.nom}, {visitor.cin}
                                            </MenuItem>
                                            <hr />
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    }
                </Grid>
                <Grid item xs={3} sm={2} md={4} xl={3}>
                    <Grid container spacing={1} sx={{
                        alignItems: "center",
                        justifyContent: "flex-end"
                    }}>
                        <Grid item xs={0} sx={{
                            display: {
                                xs: 'none',
                                md: 'flex'
                            }
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-end'
                            }}>
                                    <Box sx={{
                                        marginRight: 1,
                                        cursor: "pointer"
                                    }} title="Aide">
                                        <ButtonEffect>
                                            <img src={'/assets/icons/question.png'} alt="Question" />
                                        </ButtonEffect>
                                </Box>
                                <Box 
                                    className="MuiButtonBase-root" 
                                    sx={{
                                        marginRight: 1,
                                        cursor: "pointer"
                                    }}   
                                    onClick={()=> {
                                        setActive(3);
                                        setOpenSetting(curr => !curr);
                                    }}
                                    title="Paramètre"
                                >
                                    <ButtonEffect>
                                        <img src={'/assets/icons/gear.png'} alt="Setting" />
                                    </ButtonEffect>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <ListItemButton onClick={openLogout ? handleCloseLogout: handleOpenLogout} sx={{
                                padding: "8px",
                                borderRadius: 1
                            }}>
                                <ListItemIcon>
                                    <img src={'/assets/icons/user.png'} alt="Utilisateur" />
                                </ListItemIcon>
                                <Box sx={{
                                    display: {
                                        xs: "none",
                                        md: "flex"
                                    }
                                }}>
                                    <Grid 
                                        container 
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={8}>
                                            <Grid 
                                                container 
                                                columnSpacing={1}
                                                alignItems="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Typography style={Styles.fontTitle} sx={{
                                                        color: "#16161D"
                                                    }}>{user?.nomagent || "Jean"} {user?.prenomagent || "Doe"}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography style={{
                                                        fontFamily: "Montserrat",
                                                        fontWeight: 400,
                                                        fontSize: "0.688rem",
                                                        lineHeight: "0.963rem"
                                                    }} sx={{
                                                        color: "#6E6E91"
                                                    }}>{user?.email || ""}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs>
                                            {openLogout ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </ListItemButton>
                            <Menu
                                id="logout-menu"
                                anchorEl={anchorLogout}
                                open={openLogout}
                                onClose={handleCloseLogout}
                                elevation={0}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                            >
                                <MenuItem>
                                    <ListItemButton onClick={logOut}>
                                        <ListItemIcon>
                                            <Logout />
                                        </ListItemIcon>
                                        <Typography sx={Styles.fontTable}>
                                            Déconnexion
                                        </Typography>
                                    </ListItemButton>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
