import {
  Box,
  Grid,
  ListItem,
  Typography,
  InputBase,
  TextField,
  MenuItem,
  Slide,
  Select,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { Styles } from "../styles/style";
import { ComponentButton } from "../components/Button";
import { ComponentButtonIcon } from "../components/ButtonIcon";
import {
  ArrowBack,
  ChevronLeft,
  ChevronRight,
  Search,
} from "@mui/icons-material";
import DashBoardTable from "../components/DashBoardTable";
import badges from "../actions/badges";
import { useEffect, useState } from "react";
import enterprise from "../actions/enterprise";
import category from "../actions/category";
import uuid from "react-uuid";
import visitedPersonne from "../actions/visitedPersonne";
import visitor from "../actions/visitors";
import visite from "../actions/visite";
import { AddModal } from "../components/AddModal";
import modalSubmited from "../actions/modalSubmited";
import { useVisitorContext } from "../actions/visitorContext";
import { dateFormat, enFormatDate } from "../actions/dateFormated";
import SimpleBackdrop from "../components/BackDrop";
import { ShowColumn } from "../components/showColumn";
import { DashBoardDataTable } from "../actions/data";
import useShowColumn from "../customHook/useShowColumn";
import etatBadge from "../actions/etatBadge";

export const Dashbord = ({
  openFullMenu,
  modalForm,
  setModalForm,
  badgeOnSearch,
  setOnSearch,
  timoutList,
  setTimoutList,
  handleOpenForm,
}) => {
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleOpenBackdrop = () => {
    setOpenBackdrop(true);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const [isOnSearch, setIsOnSearch] = useState("");
  const [dateTemp, setDateTemp] = useState("");
  const [searchDate, setSearchDate] = useState(() => dateFormat(new Date()));
  const [isOnload, setIsOnload] = useState(true);
  const [isSubmited, setIsSubmited] = useState(1);
  const [idBadge, setIdBadge] = useState(null);
  const [number, setNumber] = useState(0);
  const [badge, setBadge] = useState({
    denomination: "",
    status: true,
  });

  const [badgeDisabled, setBadgeDisabled] = useState("");
  const [dataBadge, setDataBadges] = useState([]);
  const [enterpriseOptions, setEnterpriseOptions] = useState([]);
  const [enterpriseOption, setEnterpriseOption] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryOption, setCategoryOption] = useState("");
  const [visitedOptions, setVisitedOptions] = useState([]);
  const [visitedOption, setVisitedOption] = useState("");
  const [dataBody, setDataBody] = useState([]);
  const {
    isSelect,
    visitorInput,
    handleVisitorChange,
    handleSelectChange,
    handleChangeCINDate,
    handleChangeDateFormation,
    handleChangeEcheanceFormation,
    handleVisitorReset,
  } = useVisitorContext();
  const [typeEtatBadge, setTypeEtatBadge] = useState("");

  const handleChangeSearchDate = (e) => {
    const {
      target: { value },
    } = e;
    if (value.length === 0) setSearchDate("");

    setDateTemp(value);

    if (value.length < 10 || value.length > 11) return;

    const updateDate = enFormatDate(value);

    if (updateDate.toString() === "Invalid Date") return;
    setSearchDate(dateFormat(updateDate));
    setDateTemp("");
  };

  const handleDateDecrease = () => {
    const currDate = searchDate === "" ? new Date() : enFormatDate(searchDate);
    const dayBefore = currDate;
    dayBefore.setDate(dayBefore.getDate() - 1);
    setSearchDate(dateFormat(dayBefore));
  };

  const handleDateIncrease = () => {
    const currDate = searchDate === "" ? new Date() : enFormatDate(searchDate);
    const tomorrow = currDate;
    tomorrow.setDate(tomorrow.getDate() + 1);
    setSearchDate(dateFormat(tomorrow));
  };

  const handleChangeEnterpriseOption = (e) => {
    setEnterpriseOption(e.target.value);
  };

  const handleChangeCategoryOption = (e) => {
    setCategoryOption(e.target.value);
  };

  const handleChangeVisitedOption = (e) => {
    setVisitedOption(e.target.value);
  };

  const handleCloseForm = () => {
    handleVisitorReset();
    setModalForm(false);
    setTimoutList(1000);
    setEnterpriseOption("");
    setCategoryOption("");
    setVisitedOption("");
    setBadgeDisabled("");
    setBadge("");
  };

  const handleAddBadge = async () => {
    try {
      if (badge.denomination === "") return;
      handleOpenBackdrop();
      await badges.createBadges(badge);
      setBadge({
        denomination: "",
        status: true,
      });
      handleSetDataBadges();
      handleCloseBackdrop();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditBadgeInactif = async () => {
    try {
      if (badge.denomination === "") return;
      await badges.editBadge(
        {
          ...badge,
          status: false,
        },
        idBadge
      );
      setBadge({
        denomination: "",
        status: null,
      });
      handleSetDataBadges();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditBadgeActif = async (badge) => {
    try {
      const data = await badges.getBadges();
      const results = data.filter((el) => el.denomination === badge);
      if (results.length === 0) return;
      await badges.editBadge(
        {
          denomination: results.at(0).denomination,
          status: true,
        },
        results.at(0).idbadge
      );
      handleSetDataBadges();
    } catch (err) {
      throw err;
    }
  };

  const handleSetDataBadges = async (input = "") => {
    const data = await badges.filterBadges(input);
    setDataBadges(data);
    setOnSearch(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      handleOpenBackdrop();
      const visitorId =
        visitorInput?.idvisiteur || (await visitor.setVisitor(visitorInput));
      const dataJSON = {
        numvisiteur: badgeDisabled,
        visiteur: visitorId,
        personnelVisite: visitedOption,
      };
      await visite.setVisite(dataJSON);
      handleIsSubmited();
      handleVisitorReset();
      handleEditBadgeInactif();
      handleCloseBackdrop();
      handleCloseForm();
    } catch (err) {
      alert("Une erreur est survenue!");
    }
  };

  const handleIsSubmited = () => {
    setIsSubmited((prev) => prev * -1);
  };

  useEffect(() => {
    if (isSelect) {
      handleSelectChange(enterpriseOption, categoryOption);
    } else {
      handleSelectChange(
        visitorInput?.societe ? visitorInput?.societe : enterpriseOption,
        visitorInput?.categorieVisiteur
          ? visitorInput?.categorieVisiteur
          : categoryOption
      );
    }
    const enterpriseId = visitorInput?.societe;
    setEnterpriseOption(enterpriseId);
    const categoryId = visitorInput?.categorieVisiteur;
    setCategoryOption(categoryId);
  }, [
    isSelect,
    enterpriseOption,
    categoryOption,
    visitorInput,
    handleSelectChange,
  ]);

  useEffect(() => {
    const getVisiteList = async () => {
      setIsOnload(true);
      const data = await visite.getVisite();
      setDataBody(data);
      setIsOnload(false);
    };
    const getEnterprises = async () => {
      const data = await enterprise.getEnterprise();
      setEnterpriseOptions(data);
    };
    const getCategories = async () => {
      const data = await category.getCategory();
      setCategoryOptions(data);
    };
    const getVisited = async () => {
      const data = await visitedPersonne.getVisitedPersonne();
      setVisitedOptions(data);
    };
    getVisiteList();
    getEnterprises();
    getCategories();
    getVisited();
  }, [isSubmited]);

  // Nouvelle fonctionnalité
  useEffect(() => {
    const getData = async () => {
      const data = await etatBadge.getEtatBadge();
      if (data && data[0]?.idetatbadge) {
        setTypeEtatBadge(data[0]?.denomination || "Oui");
      }
    };
    getData();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: Styles.color.primary,
        padding: 0,
      }}
    >
      <Box
        sx={{
          padding: 3,
        }}
      >
        {!modalForm && (
          <Box
            style={{
              padding: "0 5px 0 10px",
            }}
          >
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} md={1}>
                <Grid
                  container
                  columnSpacing={1}
                  sx={{
                    padding: {
                      xs: "0 10px",
                      md: "0",
                    },
                  }}
                >
                  <Grid
                    item
                    xs
                    style={{
                      backgroundColor: "#ACACAC",
                      padding: "5px 0",
                      borderRadius: 4,
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Grid item>
                        <img
                          src={"/assets/icons/main.png"}
                          alt="Main"
                          width={40}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          color: "white",
                          fontSize: "2.5rem",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {number}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  style={Styles.boxModel}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    border: `1.5px solid ${Styles.color.grey}`,
                    padding: "9px 5px",
                  }}
                >
                  <Search
                    sx={{
                      marginLeft: 1,
                      color: Styles.color.grey,
                    }}
                  />
                  <InputBase
                    placeholder="Recherche..."
                    sx={{
                      fontFamily: "Montserrat",
                      border: `1px solid transparent`,
                      width: "100%",
                      padding: "0 9px",
                      color: Styles.color.grey,
                    }}
                    value={isOnSearch}
                    onChange={(e) => setIsOnSearch(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          marginLeft: 1,
                        }}
                      >
                        <ComponentButtonIcon
                          backgroundColor={"#fff"}
                          color={"#8D9496"}
                          onClick={handleDateDecrease}
                          padding={14.5}
                          border={`1px solid ${Styles.color.grey}`}
                          borderRadius={5}
                        >
                          <ChevronLeft />
                        </ComponentButtonIcon>
                      </Box>
                      <Box
                        sx={{
                          marginLeft: 1,
                          borderRadius: 2,
                        }}
                      >
                        <TextField
                          value={dateTemp ? dateTemp : searchDate}
                          sx={{
                            width: "100%",
                          }}
                          InputProps={{
                            sx: {
                              "& .MuiInputBase-input": {
                                textAlign: "center",
                              },
                              padding: "1.75rem 0",
                              backgroundColor: "#fff",
                              color: Styles.color.darkGrey,
                            },
                            style: Styles.propsTextField,
                          }}
                          onChange={handleChangeSearchDate}
                        />
                      </Box>
                      <Box
                        sx={{
                          marginLeft: 1,
                        }}
                      >
                        <ComponentButtonIcon
                          backgroundColor={"#fff"}
                          color={"#8D9496"}
                          onClick={handleDateIncrease}
                          padding={14.5}
                          border={`1px solid ${Styles.color.grey}`}
                          borderRadius={5}
                        >
                          <ChevronRight />
                        </ComponentButtonIcon>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        {modalForm && (
          <Slide direction="left" in={modalForm} timeout={1000}>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  position: "relative",
                  marginTop: 1,
                }}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Grid
                    item
                    sx={{
                      backgroundColor: "#fff",
                      borderRadius: 1,
                      marginLeft: 1,
                      padding: "6px 11px",
                      boxShadow: "0px 4px 20px 0px #0000001A",
                      cursor: "pointer",
                    }}
                    onClick={handleCloseForm}
                  >
                    <ArrowBack
                      sx={{
                        color: Styles.color.grey,
                        marginTop: 0.4,
                      }}
                      titleAccess="Retourné au tableau de bord"
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      right: "7%",
                      backgroundColor: "#fff",
                      width: "88.5%",
                      borderRadius: 1,
                      padding: 0.5,
                      boxShadow: "0px 4px 20px 0px #0000001A",
                    }}
                  >
                    <Box
                      style={Styles.boxModel}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Search
                        sx={{
                          marginLeft: 1,
                          color: "#888",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOnSearch((prev) => !prev);
                        }}
                      />
                      <InputBase
                        placeholder="N° BADGE"
                        sx={{
                          fontFamily: Styles.inputDefaultTypo,
                          border: `1px solid transparent`,
                          width: "100%",
                          padding: "0 10px",
                        }}
                        value={badge.denomination}
                        onChange={(e) => {
                          setBadge({
                            ...badge,
                            denomination: e.target.value,
                          });
                          handleSetDataBadges(e.target.value);
                          if (!e.target.value) setOnSearch(false);
                        }}
                        onFocus={(e) => handleSetDataBadges(e.target.value)}
                      />
                    </Box>
                    {badgeOnSearch && (
                      <>
                        <hr
                          style={{
                            border: "1.5px solid #878787",
                          }}
                        />
                        <Box
                          sx={{
                            fontFamily: Styles.inputDefaultTypo,
                            maxHeight: 400,
                          }}
                        >
                          {dataBadge.map((badge) => (
                            <MenuItem
                              key={badge.idbadge}
                              onClick={() => {
                                setBadgeDisabled(badge.denomination);
                                setIdBadge(badge.idbadge);
                                setBadge({ ...badge });
                                setOnSearch(false);
                              }}
                              sx={{
                                color: Styles.color.grey,
                              }}
                            >
                              {badge.denomination}
                            </MenuItem>
                          ))}
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      paddingTop: "0 !important",
                    }}
                  >
                    <ComponentButton
                      backgroundColor={"#D9313F"}
                      color={"#FFFFFF"}
                      lineHeight={"2.125rem"}
                      onClick={handleAddBadge}
                      type="button"
                      title="Ajout badge"
                    >
                      <Box>+</Box>
                    </ComponentButton>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    marginTop: 4,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <ListItem
                        style={Styles.boxModel}
                        alignItems="flex-start"
                        sx={{
                          paddingBottom: 3,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Grid container direction="row">
                              <Typography style={Styles.typography}>
                                Nom *
                              </Typography>
                              <TextField
                                placeholder="..."
                                sx={{
                                  width: "100%",
                                }}
                                InputProps={{
                                  sx: Styles.propsTextField,
                                }}
                                name="nom"
                                value={visitorInput.nom}
                                onChange={handleVisitorChange}
                                required
                                disabled={!isSelect}
                              />
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                                alignItems="flex-end"
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <Grid item xs>
                                  <Typography style={Styles.typography}>
                                    Société *
                                  </Typography>
                                  <Select
                                    sx={{
                                      width: "100%",
                                      height: "2.5rem",
                                      "& .MuiOutlinedInput-input.Mui-disabled":
                                        {
                                          WebkitTextFillColor: "#000000",
                                          opacity: 1,
                                        },
                                    }}
                                    style={Styles.inputDefaultTypo}
                                    value={enterpriseOption}
                                    onChange={handleChangeEnterpriseOption}
                                    required
                                    disabled={!isSelect}
                                  >
                                    {enterpriseOptions.map((enterprise) => (
                                      <MenuItem
                                        key={uuid()}
                                        value={enterprise.idsociete}
                                      >
                                        {enterprise.denomination}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                                {isSelect && (
                                  <AddModal
                                    title={"Société"}
                                    handleSubmit={
                                      modalSubmited.handleAddEnterprise
                                    }
                                    handleIsSubmited={handleIsSubmited}
                                    handleOpenBackdrop={handleOpenBackdrop}
                                    handleCloseBackdrop={handleCloseBackdrop}
                                  />
                                )}
                              </Grid>
                              <Typography style={Styles.typography}>
                                N° CIN *
                              </Typography>
                              <TextField
                                placeholder="..."
                                sx={{ width: "100%" }}
                                InputProps={{
                                  sx: Styles.propsTextField,
                                }}
                                name="cin"
                                value={visitorInput.cin}
                                onChange={handleVisitorChange}
                                required
                                disabled={!isSelect}
                              />
                              <Typography style={Styles.typography}>
                                Formation sécurité
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label={
                                    <Typography
                                      sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "inherit",
                                      }}
                                    >
                                      ...
                                    </Typography>
                                  }
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      sx: {
                                        width: "11rem",
                                        "& .MuiOutlinedInput-input":
                                          Styles.inputDefaultTypo,
                                        "& .MuiOutlinedInput-input.Mui-disabled":
                                          {
                                            WebkitTextFillColor: "#000000",
                                            opacity: 1,
                                          },
                                      },
                                      style: { width: "100%" },
                                    },
                                  }}
                                  onChange={handleChangeDateFormation}
                                  disabled={!isSelect}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container direction="row">
                              <Typography style={Styles.typography}>
                                Prénom *
                              </Typography>
                              <TextField
                                placeholder="..."
                                sx={{ width: "100%" }}
                                InputProps={{
                                  sx: Styles.propsTextField,
                                }}
                                name="prenom"
                                value={visitorInput.prenom}
                                onChange={handleVisitorChange}
                                required
                                disabled={!isSelect}
                              />
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                                alignItems="flex-end"
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <Grid item xs>
                                  <Typography style={Styles.typography}>
                                    Catégorie *
                                  </Typography>
                                  <Select
                                    sx={{
                                      width: "100%",
                                      height: "2.5rem",
                                      "& .MuiOutlinedInput-input.Mui-disabled":
                                        {
                                          WebkitTextFillColor: "#000000",
                                          opacity: 1,
                                        },
                                    }}
                                    style={Styles.inputDefaultTypo}
                                    value={categoryOption}
                                    onChange={handleChangeCategoryOption}
                                    required
                                    disabled={!isSelect}
                                  >
                                    {categoryOptions.map((category) => (
                                      <MenuItem
                                        key={uuid()}
                                        value={category.idcategorievisiteur}
                                      >
                                        {category.libelle}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                                {isSelect && (
                                  <AddModal
                                    title={"Catégorie"}
                                    handleSubmit={
                                      modalSubmited.handleAddCategory
                                    }
                                    handleIsSubmited={handleIsSubmited}
                                    handleOpenBackdrop={handleOpenBackdrop}
                                    handleCloseBackdrop={handleCloseBackdrop}
                                  />
                                )}
                              </Grid>
                              <Typography style={Styles.typography}>
                                Echéance CIN
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label={
                                    <Typography
                                      sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "inherit",
                                      }}
                                    >
                                      ...
                                    </Typography>
                                  }
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      sx: {
                                        width: "11rem",
                                        "& .MuiOutlinedInput-input":
                                          Styles.inputDefaultTypo,
                                        "& .MuiOutlinedInput-input.Mui-disabled":
                                          {
                                            WebkitTextFillColor: "#000000",
                                            opacity: 1,
                                          },
                                      },
                                      style: { width: "100%" },
                                    },
                                  }}
                                  onChange={handleChangeCINDate}
                                  disabled={!isSelect}
                                />
                              </LocalizationProvider>
                              <Typography style={Styles.typography}>
                                Echéance formation sécurité
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  label={
                                    <Typography
                                      sx={{
                                        fontFamily: "Montserrat",
                                        fontSize: "inherit",
                                      }}
                                    >
                                      ...
                                    </Typography>
                                  }
                                  slotProps={{
                                    textField: {
                                      size: "small",
                                      sx: {
                                        "& .MuiOutlinedInput-input":
                                          Styles.inputDefaultTypo,
                                        "& .MuiOutlinedInput-input.Mui-disabled":
                                          {
                                            WebkitTextFillColor: "#000000",
                                            opacity: 1,
                                          },
                                      },
                                      style: { width: "100%" },
                                    },
                                  }}
                                  onChange={handleChangeEcheanceFormation}
                                  disabled={!isSelect}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ListItem style={Styles.boxModel} alignItems="flex-start">
                        <Grid container justifyContent="flex-end">
                          <Grid item xs={12}>
                            <Grid container spacing={2}>
                              <Grid item xs>
                                <Grid container direction="row">
                                  <Grid
                                    container
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    sx={{
                                      width: "100%",
                                    }}
                                  >
                                    <Grid item xs>
                                      <Typography style={Styles.typography}>
                                        Visité *
                                      </Typography>
                                      <Select
                                        sx={{
                                          width: "100%",
                                          height: "2.5rem",
                                        }}
                                        style={Styles.inputDefaultTypo}
                                        value={visitedOption}
                                        onChange={handleChangeVisitedOption}
                                        required
                                      >
                                        {visitedOptions.map((visited) => (
                                          <MenuItem
                                            key={uuid()}
                                            value={visited.idpersonnelvisite}
                                          >
                                            {visited.nom} {visited.prenom}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </Grid>
                                  </Grid>
                                  <Typography style={Styles.typography}>
                                    N° de badge fourni{" "}
                                    {typeEtatBadge === "Oui" && "*"}
                                  </Typography>
                                  <TextField
                                    placeholder={
                                      typeEtatBadge !== "Non" && "..."
                                    }
                                    sx={{ width: "100%" }}
                                    InputProps={{
                                      sx: Styles.propsTextField,
                                    }}
                                    value={badgeDisabled}
                                    onChange={() => {
                                      return;
                                    }}
                                    disabled={typeEtatBadge === "Non"}
                                    required={typeEtatBadge === "Oui"}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            sx={{
                              marginBlockStart: {
                                xs: 2,
                                md: "160px",
                              },
                              marginBlockEnd: 3,
                            }}
                          >
                            <ComponentButton
                              backgroundColor={"#D9313F"}
                              color={"#FFFFFF"}
                              lineHeight={"24px"}
                            >
                              Entrée
                            </ComponentButton>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </form>
          </Slide>
        )}
      </Box>
      {!modalForm && (
        <Slide direction="up" in={!modalForm} timeout={timoutList}>
          <Box>
            <ListData
              openFullMenu={openFullMenu}
              dataBody={dataBody}
              isOnload={isOnload}
              isOnSearch={isOnSearch}
              searchDate={searchDate}
              setIsSubmited={setIsSubmited}
              setNumber={setNumber}
              handleEditBadgeActif={handleEditBadgeActif}
            />
          </Box>
        </Slide>
      )}
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
    </Box>
  );
};

const ListData = ({
  openFullMenu,
  dataBody,
  isOnload,
  setIsSubmited,
  isOnSearch,
  searchDate,
  handleEditBadgeActif,
  setNumber,
}) => {
  const [HeaderTable, setHeaderTable] = useState(() => DashBoardDataTable);
  const {
    open,
    anchorEl,
    menu,
    handleOpenRow,
    handleCloseRow,
    handleShowColumn,
  } = useShowColumn(HeaderTable, setHeaderTable);

  return (
    <Box
      sx={{
        padding: 3,
        paddingTop: 0,
      }}
    >
      <Box
        sx={{
          marginBottom: 3,
        }}
      >
        <ShowColumn
          open={open}
          anchorEl={anchorEl}
          handleOpenRow={handleOpenRow}
          handleCloseRow={handleCloseRow}
          handleShowColumn={handleShowColumn}
          menu={menu}
        />
      </Box>
      <DashBoardTable
        openFullMenu={openFullMenu}
        dataBody={dataBody}
        dataHeader={HeaderTable}
        isOnload={isOnload}
        setIsSubmited={setIsSubmited}
        setNumber={setNumber}
        isOnSearch={isOnSearch}
        searchDate={searchDate}
        handleEditBadgeActif={handleEditBadgeActif}
        menu={menu}
      />
    </Box>
  );
};
