import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Styles } from "../styles/style";

export const Edit = ({ link = "#", state = null })=> {
    return(
        <Link to={link} state={{
            from: state
        }}>
            <Box sx={{
                padding: 1,
                paddingBottom: 0.5,
                maxHeight: 25,
                backgroundColor: Styles.color.inputRed,
                borderRadius: 2,
                marginLeft: 1,
                cursor: 'pointer'
            }} title="editer">
                <img width={20} src={'/assets/icons/edit.png'} alt="Edit" />
            </Box>
        </Link>
    )
}

export const Delete = ({ link = "#", state = null })=> {
    return(
        <Link to={link} state={{
            from: state
        }}>
            <Box sx={{
                padding: 1,
                paddingBottom: 0.5,
                maxHeight: 25,
                backgroundColor: Styles.color.grey,
                borderRadius: 2,
                marginLeft: 1,
                cursor: 'pointer'
            }} title="supprimer">
                <img width={20} src={'/assets/icons/delete.png'} alt="Delete" />
            </Box>
        </Link>
    )
}