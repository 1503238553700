import API_URL from "./config";
import Construct from "./construct";

class ObjectClass extends Construct {

    async _createJSONObject (object) {
        return await {
            ...object,
            etatobjet: true,
            idclientAdmin: await this.getClientAdmin() ? `${await this.getClientAdmin()}` : null
        }
    }

    async setObject(object) {
        try {
            const response = await this._request.post(`${API_URL}/typeobjets`, await this._createJSONObject(object))
            return response;
        } catch (err) {
            throw err;
        }
    }

    async editObject(object, id) {
        try {
            const response = await this._request.put(`${API_URL}/typeobjets/${id}`, await this._createJSONObject(object));
            return response;
        } catch (err) {
            throw err;
        }
    }

    async getObject() {
        try {
            const response = await this._request.get(`${API_URL}/typeobjets`);
            const { data } = response;
            const result = await this.isData(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    
    async getObjectAdmin() {
        try {
            const response = await this._request.get(`${API_URL}/typeobjets`);
            const { data } = response;
            const result = await this.isDataAdmin(data);
            return result;
        } catch (err) {
            throw err;
        }
    }

    async deleteObject(object, id) {
        try {
            const _object = await this._createJSONObject(object);
            const response = await this._request.put(`${API_URL}/typeobjets/${id}`, {
                ..._object,
                estActif: false,
            });
            return response;
        } catch (err) {
            throw err;
        }
    }

}
const object = new ObjectClass();
export default object;
