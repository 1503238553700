import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography, Button, Modal } from '@mui/material';
import { Styles } from '../styles/style';
import { LoadingData, NullData } from './Loading';
import users from '../actions/users';
import { Edit } from './ButtonAction';
import { StyledTableCell, StyledTableRow } from '../styles/style';

const cellTableHead = (content, key)=> {
    return (
        <StyledTableCell key={key} align='left'>
            <Box sx={{
                color: Styles.color.darkGrey,
                textTransform: 'capitalize',
                padding: 1
            }} style={Styles.tableHeaderFont}>
                { content }
            </Box>
        </StyledTableCell>
    );
}

export default function UsersTable({ 
    openFullMenu, 
    dataHeader, 
    isOnload, 
    dataBody, 
    handleDeleteUser 
}) {

    const [openDelete, setOpenDelete] = React.useState(false);
    const [user, setUser] = React.useState({});

    const handleOpenDelete = (value)=> {
        setOpenDelete(true);
        setUser({
            id: value.id,
            email: value.email,
            password: "",
            nomagent: value.nomagent || "",
            prenomagent: value.prenomagent || "",
            matriculeagent: value.matriculeagent || "",
            telagent: value.telagent || "",
            idclientAdmin: value.idclientAdmin || null,
            roles: value.roles
        });
    }

    const handleCloseDelete = ()=> {
        setOpenDelete(false);
    }

    const handleSubmitDelete = async ()=> {
        try {
            const response = await users.deleteUsers(user, user.id);
            if (response.status === 200)
                handleDeleteUser();
        } catch (err) {
            alert("Un erreur est survenu")
        }
        handleCloseDelete();
    }

    const maxWidth = openFullMenu && window.innerWidth > 1200 ? 
    window.innerWidth - 420 : window.innerWidth;

    return (
        <Box style={{
            maxWidth: `${maxWidth}px`
        }}>
            <TableContainer 
                component={Paper}
                sx={{
                    boxShadow: "none"
                }}
            >
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {dataHeader.map((content, key) => cellTableHead(content, key))}
                            <StyledTableCell align='right' style={Styles.tableHeaderFont}>
                                <Box sx={{
                                    marginRight: 3
                                }}>
                                    Action
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            dataBody.length === 0 ?
                                <NullData length={dataHeader.length} />
                            :
                            dataBody.map((value, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell style={Styles.fontTable}>
                                    { value?.nomagent || "...." }
                                </StyledTableCell>
                                <StyledTableCell style={Styles.fontTable}>
                                    { value?.prenomagent || "...." }
                                </StyledTableCell>
                                <StyledTableCell style={Styles.fontTable}>
                                    { (value?.email).replace(`__delete__${value.id}__`, "") || "...." }
                                </StyledTableCell>
                                <StyledTableCell style={Styles.fontTable}>
                                    { value?.telagent || "...." }
                                </StyledTableCell>
                                <StyledTableCell style={Styles.fontTable}>
                                    { value?.matriculeagent || "...." }
                                </StyledTableCell>
                                <StyledTableCell style={Styles.fontTable}>
                                    { 
                                        ((value?.roles.at(-1) === "ROLE_SUPER_ADMIN" && "SUPER ADMIN") ||
                                        (value?.roles.at(-1) === "ROLE_ADMIN" && "ADMIN") ||
                                        (value?.roles.at(-1) === "ROLE_USER" && "AGENT")) ||
                                        (value.roles.at(-1) || "....")
                                    }
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Edit link={`/clients`} state={value}/>
                                        <Box sx={{
                                                padding: 1,
                                                paddingBottom: 0.5,
                                                maxHeight: 25,
                                                backgroundColor: Styles.color.grey,
                                                borderRadius: 2,
                                                marginLeft: 1,
                                                cursor: 'pointer'
                                            }} title="supprimer" 
                                            onClick={() => handleOpenDelete(value)}
                                        >
                                            <img width={20} src={'/assets/icons/delete.png'} alt="Delete" />
                                        </Box>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
            >
                <Box sx={Styles.modals}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Suppression de client
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    Êtes-vous vraiment sûr de supprimer ce client ?
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginY: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained"
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleCloseDelete}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                    "&:hover": {
                                        backgroundColor: Styles.color.red
                                }
                            }}
                            onClick={handleSubmitDelete}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}