import { Grid, CircularProgress } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Styles } from "../styles/style";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor:  Styles.color.dark,
        color: theme.palette.common.white,
        padding: 8
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "#E7E7E733"
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));


export const Loading = ()=> (
    <Grid container justifyContent="center" alignItems="center" sx={{
        height: 100
    }}>
        <CircularProgress color='success' />
    </Grid>
)

export const LoadingData = ({ length = 1 }) => (
    <StyledTableRow>
        <StyledTableCell align='center' colSpan={length + 1}>
            <Grid container justifyContent="center" alignItems="center" sx={{
                height: 400
            }}>
                <Grid item xs>
                    <CircularProgress color='success' />
                </Grid>
            </Grid>
        </StyledTableCell>
    </StyledTableRow>
)

export const NullData = ({ length = 1, right = 0 }) => (
    <StyledTableRow>
        <StyledTableCell align='center' colSpan={length + 1}>
            <Grid container justifyContent="center" alignItems="center" sx={{
                height: 300
            }}>
                <Grid item xs sx={Styles.fontTable} style={{
                    marginRight: right
                }}>
                    Aucun donnée trouvé
                </Grid>
            </Grid>
        </StyledTableCell>
    </StyledTableRow>
)