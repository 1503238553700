import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Grid, Typography, Button, Modal, TextField } from '@mui/material';
import { Styles } from '../styles/style';
import { LoadingData, NullData } from './Loading';
import { StyledTableCell, StyledTableRow } from '../styles/style';
import profils from '../actions/profils';
import { useState } from 'react';

const cellTableHead = (content, key)=> {
    return (
        <StyledTableCell key={key} align='left'>
            <Box sx={{
                color: Styles.color.darkGrey,
                textTransform: 'capitalize',
                padding: 1
            }} style={Styles.tableHeaderFont}>
                { content }
            </Box>
        </StyledTableCell>
    );
}

export default function ProfilsTable({ 
    openFullMenu, 
    dataHeader, 
    isOnload, 
    dataBody, 
    handleIsSubmitProfil 
}) {

    const [openDelete, setOpenDelete] = React.useState(false);
    const [profil, setProfil] = React.useState({});
    const [validation, setValidation] = useState("");
    const [open, setOpen] = useState(false);

    const handleOpenDelete = (value)=> {
        setOpenDelete(true);
        setProfil({
            id: value.idprofil,
            denomination: value.denomination
        });
    }

    const handleCloseDelete = ()=> {
        setOpenDelete(false);
    }

    const handleSubmitDelete = async ()=> {
        try {
            await profils.deleteProfil(profil, profil.id);
            handleIsSubmitProfil();
        } catch (err) {
            alert("Un erreur est survenu")
        }
        handleCloseDelete();
    }

    const handleOpen = (value)=> {
        setOpen(true);
        setProfil({
            id: value.idprofil
        });
        setValidation(value.denomination);
    }
    const handleClose = ()=> {
        setOpen(false);
    }
    const handleSubmitProfil = async ()=> {
        try {
            await profils.editProfil({
                denomination: validation
            }, profil.id);
            handleIsSubmitProfil()
        } catch (err) {
            alert("Un erreur est survenu");
        }
        console.log(profil.id, validation);
    }

    const maxWidth = openFullMenu && window.innerWidth > 1200 ? 
    window.innerWidth - 420 : window.innerWidth;

    return (
        <Box style={{
            maxWidth: `${maxWidth}px`
        }}>
            <TableContainer 
                component={Paper}
                sx={{
                    boxShadow: "none"
                }}
            >
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                        <TableRow>
                            {dataHeader.map((content, key) => cellTableHead(content, key))}
                            <StyledTableCell align='right' style={Styles.tableHeaderFont}>
                                <Box sx={{
                                    marginRight: 3
                                }}>
                                    Action
                                </Box>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            isOnload ?
                                <LoadingData length={dataHeader.length}/>
                            :
                            dataBody.length === 0 ?
                                <NullData length={dataHeader.length} />
                            :
                            dataBody.map((value, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell style={Styles.fontTable}>
                                    { value?.denomination || "...." }
                                </StyledTableCell>
                                <StyledTableCell align='right'>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Box sx={{
                                            padding: 1,
                                            paddingBottom: 0.5,
                                            maxHeight: 25,
                                            backgroundColor: Styles.color.inputRed,
                                            borderRadius: 2,
                                            marginLeft: 1,
                                            cursor: 'pointer'
                                        }} title="editer"
                                            onClick={()=> handleOpen(value)}
                                        >
                                            <img width={20} src={'/assets/icons/edit.png'} alt="Edit" />
                                        </Box>
                                        <Box sx={{
                                                padding: 1,
                                                paddingBottom: 0.5,
                                                maxHeight: 25,
                                                backgroundColor: Styles.color.grey,
                                                borderRadius: 2,
                                                marginLeft: 1,
                                                cursor: 'pointer'
                                            }} title="supprimer" 
                                            onClick={() => handleOpenDelete(value)}
                                        >
                                            <img width={20} src={'/assets/icons/delete.png'} alt="Delete" />
                                        </Box>
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={Styles.modals}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Ajout profile
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    Dénomination
                                </Typography>
                                <TextField placeholder="Dénomination *"  sx={{ width: '100%' }}
                                    InputProps={{
                                        style: Styles.propsTextField
                                    }}
                                    onChange={e => setValidation(e.target.value)}
                                    value={validation}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginY: 3,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleClose}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: Styles.color.red
                                }
                            }} 
                        onClick={()=> {
                            if (!validation) return
                            handleSubmitProfil()
                            setValidation("");
                            handleClose();
                        }}>
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openDelete}
                onClose={handleCloseDelete}
            >
                <Box sx={Styles.modals}>
                    <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item sx={Styles.fontTitle}>
                            Suppression de profile
                        </Grid>
                    </Grid>
                    <hr style={{ color: Styles.color.blue, margin: "15px auto" }}/>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Typography style={ Styles.typography }>
                                    Êtes-vous vraiment sûr de supprimer le profile ?
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginY: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end"
                    }}>
                        <Button variant="contained"
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: "#8D9496",
                                marginRight: 2,
                                width: "100%",
                                "&:hover": {
                                    backgroundColor: "#8D9496"
                            }
                        }} onClick={handleCloseDelete}>
                            Annuler
                        </Button>
                        <Button variant="contained" 
                            style={Styles.fontDefaultButton}
                            sx={{
                                backgroundColor: Styles.color.red,
                                width: "100%",
                                    "&:hover": {
                                        backgroundColor: Styles.color.red
                                }
                            }}
                            onClick={handleSubmitDelete}
                        >
                            Valider
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}