import { Grid, List, ListItemButton, ListItemIcon, Box, Typography } from "@mui/material"
import { Link } from "react-router-dom";
import { Styles } from "../styles/style";
import { ButtonEffect } from "./ButtonIcon";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const NavbarAdmin = ({ size, setOpenFullMenu }) => {
    const location = useLocation();
    const active = useMemo(() => {
        return location.pathname === "/" ? 0 : 1
    }, [location])
    const handleClickNav = () => {
        if (size >= 1366) return;
        setOpenFullMenu(false);
    }
    return (
        <Grid item style={{
            backgroundColor: '#fff',
            width: 270,
            minHeight: "100vh",
            position: "fixed",
            zIndex: 3,
            overflowY: "auto"
        }}>
            <img src={'/assets/icons-nav/Vimasys.png'} alt="Vimasys" style={{
                display: "block",
                margin: "3rem auto 2rem auto"
            }} width={200} />
            <List
                sx={{
                    width: "100%",
                    maxWidth: 360,
                    color: Styles.color.defaultText
                }}
                component="nav"
            >
                <Link to='/' style={Styles.Link} onClick={handleClickNav}>
                    <ListItemButton style={{
                        backgroundColor: active === 0 ? "#D9313F" : ""
                    }} sx={Styles.buttonHover}>
                        <ListItemIcon>
                            {active === 0 ? (
                                <img src={'/assets/icons/document.png'} alt="Rapports" />
                                ) : (
                                <img src={'/assets/icons-nav/feedback.png'} alt="Rapports" />
                            )}
                        </ListItemIcon>
                        <Typography style={Styles.fontTitle} sx={active === 0 ? { color: 'white' } : { color: Styles.color.darkGrey }}>Liste des clients</Typography>
                    </ListItemButton>
                </Link>
                <Link to='/clients' style={Styles.Link} onClick={handleClickNav}>
                    <ListItemButton style={{
                        backgroundColor: active === 1 ? "#D9313F" : "",
                    }} sx={Styles.buttonHover}>
                        <ListItemIcon>
                            {active === 1 ? (
                                <img src={'/assets/icons/visitor.png'} alt="dashbord" />
                            ) : (
                                <img src={'/assets/icons-nav/visitor.png'} alt="dashbord" />
                            )}
                        </ListItemIcon>
                        <Typography style={Styles.fontTitle} sx={active === 1 ? { color: 'white' } : { color: Styles.color.darkGrey }}>Ajout client</Typography>
                    </ListItemButton>
                </Link>
            </List>
            <Grid item xs sx={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 20,
                margin: "auto",
                display: {
                    xs: 'flex',
                    md: 'none'
                }
            }}>
                <Grid container justifyContent="center" alignItems="flex-end">
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "flex-end"
                    }}>
                        <ButtonEffect>
                            <img src={'/assets/icons/question.png'} alt="Question" />
                        </ButtonEffect>
                        <ButtonEffect>
                            <img src={'/assets/icons/gear.png'} alt="Setting" />
                        </ButtonEffect>
                        <ButtonEffect>
                            <img src={'/assets/icons/bell.png'} alt="Notification" />
                        </ButtonEffect>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}