import enterprise from "./enterprise";
import category from "./category";

class ModalSubmited {
    
    async handleAddEnterprise (value) {
        try {
            await enterprise.setEnterprise(value);
        } catch (err) {
            alert("Un erreur est survenue");
        }
    }

    async handleAddCategory (value) {
        try {
            await category.setCategory(value);
        } catch (err) {
            alert("Un erreur est survenue");
        }
    }
}

const modalSubmited = new ModalSubmited();
export default modalSubmited;