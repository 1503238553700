import { Box } from "@mui/material"

export const ShadowBox = ({ children })=> {
    return(
        <Box sx={{
            padding: 5
        }}>
            <Box
                sx={{
                    boxShadow: "0px 4px 20px 0px #0000001A",
                    borderRadius: 1.5,
                    padding: "1.5rem",
                    backgroundColor: "#fff"
                }}
            >
                { children }
            </Box>
        </Box>
    )
}