import { 
    Grid, 
    Box, 
    Typography, 
    TextField,
} from "@mui/material";
import { ShadowBox } from "../../components/BoxShadow";
import { Styles } from "../../styles/style";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { ComponentButton } from "../../components/Button";
import { StatusHead } from "../../components/AddAdminHeader";
import { Link } from "react-router-dom";
import { slugs } from "../../actions/data";
import enterprise from "../../actions/enterprise";
import category from "../../actions/category";
import visitor from "../../actions/visitors";
import visitedPersonne from "../../actions/visitedPersonne";
import object from "../../actions/object";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SameTop } from "../../components/SameTop";
import badges from "../../actions/badges";

export const DeleteList = ()=> {
    const location = useLocation();
    const { slug } = useParams();
    const { from } = location?.state || {};
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [enterpriseCategory, setEnterpriseCategory] = useState(from ? from?.libelle || from?.denomination : "");
    const visitorInput = from ? {
        ...from,
        cinDate: null,
        cinDateEcheance: null,
        dateFormation: null,
        dateFormationEcheance: null
    } : {
        nom: "",
        prenom: "",
        email: "",
        cin: "",
        cinDate: null,
        cinDateEcheance: null,
        formationSecurite: "",
        dateFormation: null,
        dateFormationEcheance: null,
        estActif: true,
        tel: "",
        remarque: "",
        categorieVisiteur: "",
        societe: ""
    };
    const visited = from ? {
        ...from
    } : {
        nom: "",
        prenom: "",
        email: "",
        tel: "",
        estActif: true
    }
    const badge = from ? {
        ...from
    } : {
        denomination: "",
        etatbadge: true,
    };
    const handleDelete = (e)=> {
        e.preventDefault();
        switch (slug) {
            case "Visitor": {
                const deleteVisitor = async ()=> {
                    try {
                        if (!from) return;
                        
                        await visitor.deleteVisitor(from, from.idvisiteur);
                        
                        navigate(`/admin/${slug}`);
                    } catch (err) {
                        setError("Visiteur non supprimé!");
                    }
                }
                deleteVisitor();
                break;
            }
            case "Visited":  {
                const deleteVisited = async ()=> {
                    try {
                        if (!from) return;
                        
                        await visitedPersonne.deleteVisitedPersonne(from, from.idpersonnelvisite);
                        
                        navigate(`/admin/${slug}`);
                    } catch (err) {
                        setError("Visité non supprimé!");
                    }
                }
                deleteVisited();
                break;
            }
            case "Enterprise": {
                const deleteEnterprise = async ()=> {
                    try {
                        if (!from) return;
                        
                        await enterprise.deleteEnterprise(from, from.idsociete);
                        
                        navigate(`/admin/${slug}`);
                    } catch (err) {
                        setError("Entreprise non supprimé!");
                    }
                }
                deleteEnterprise();
                break;
            }
            case "Category": {
                const deleteCategory = async ()=> {
                    try {
                        if (!from) return;

                        await category.deleteCategory(from, from.idcategorievisiteur )
                        
                        navigate(`/admin/${slug}`);
                    } catch (err) {
                        setError("Catégorie non supprimé!");
                    }
                }
                deleteCategory();
                break;
            }
            case "Object": {
                const deleteObject = async ()=> {
                    try {
                        if (!from) return;

                        await object.deleteObject(from, from.idtypeobjet)
                        
                        navigate(`/admin/${slug}`);
                    } catch (err) {
                        setError("Objet non supprimé!");
                    }
                }
                deleteObject();
                break;
            }
            case "Badge": {
                const deleteObject = async ()=> {
                    try {
                        if (!from) return;

                        await badges.deleteBadge(from, from.idbadge)
                        
                        navigate(`/admin/${slug}`);
                        
                    } catch (err) {
                        setError("Badge non supprimé!");
                    }
                }
                deleteObject();
                break;
            }
            default: return;
        }
    }

    return(
        <form onSubmit={handleDelete}>
            <Typography sx={Styles.formControlLabel} style={{
                marginTop: 40,
                textAlign: "center",
                color: Styles.color.inputRed
            }}>
                Etes-vous sur de supprimer le {slugs[slug]}
            </Typography>
            <ShadowBox>
                {
                    error &&
                    <Typography sx={{
                        fontFamily: "Montserrat",
                        fontSize: "0.8rem",
                        color: Styles.color.red,
                        textAlign: "center",
                        paddingTop: 1,
                        paddingBottom: 0
                    }}>{ error }</Typography>
                }
                {slug==="Visitor" &&
                    <>
                        <SameTop>
                            <StatusHead slug={slugs[slug]} handleChangeCheked={null} chekedStatus={true}/>
                        </SameTop>
                        <hr style={{ color: Styles.color.blue }}/>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Typography style={ Styles.typography }>
                                        Nom
                                    </Typography>
                                    <TextField placeholder="Nom"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        name="nom"
                                        value={visitorInput.nom}
                                        disabled
                                    />
                                    <Typography style={ Styles.typography }>
                                        CIN
                                    </Typography>
                                    <TextField placeholder="CIN"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        name="cin"
                                        value={visitorInput.cin}
                                        disabled
                                    />
                                    <Typography style={ Styles.typography }>
                                        Téléphone
                                    </Typography>
                                    <TextField placeholder="Téléphone"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        name="tel"
                                        value={visitorInput.tel}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Typography style={ Styles.typography }>
                                        Prénom
                                    </Typography>
                                    <TextField placeholder="Prénom"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        name="prenom"
                                        value={visitorInput.prenom}
                                        disabled
                                    />
                                    <Typography style={ Styles.typography }>
                                        Email
                                    </Typography>
                                    <TextField placeholder="Email"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField,
                                            type: "email"
                                        }}
                                        name="email"
                                        value={visitorInput.email}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    slug==="Visited" &&
                    <>
                        <SameTop>
                            <StatusHead slug={slugs[slug]} handleChangeCheked={null} chekedStatus={true} />
                        </SameTop>
                        <hr style={{ color: Styles.color.blue }}/>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Typography style={ Styles.typography }>
                                        Nom
                                    </Typography>
                                    <TextField placeholder="Nom"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        name="nom"
                                        value={visited.nom}
                                        disabled
                                    />
                                    <Typography style={ Styles.typography }>
                                        Téléphone
                                    </Typography>
                                    <TextField placeholder="Téléphone"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        name="tel"
                                        value={visited.tel}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction="row">
                                    <Typography style={ Styles.typography }>
                                        Prénom
                                    </Typography>
                                    <TextField placeholder="Prénom"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        name="prenom"
                                        value={visited.prenom}
                                        disabled
                                    />
                                    <Typography style={ Styles.typography }>
                                        Email
                                    </Typography>
                                    <TextField placeholder="Email"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField,
                                            type: "email"
                                        }}
                                        name="email"
                                        value={visited.email}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    (slug==="Enterprise" || slug==="Category") &&
                    <>
                        <SameTop>
                            <Grid item sx={Styles.fontTitle}>
                                { slugs[slug] }
                            </Grid>
                        </SameTop>
                        <hr style={{ color: Styles.color.blue }}/>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Grid container direction="row">
                                    <Typography style={ Styles.typography }>
                                        Dénomination
                                    </Typography>
                                    <TextField placeholder="Dénomination"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        value={enterpriseCategory}
                                        onChange={e => setEnterpriseCategory(e.target.value)}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    slug==="Object" &&
                    <>
                        <SameTop>
                            <StatusHead slug={slugs[slug]} handleChangeCheked={null} chekedStatus={true}/>
                        </SameTop>
                        <hr style={{ color: Styles.color.blue, margin: "21px auto" }}/>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Grid container direction="row">
                                    <Typography style={ Styles.typography }>
                                        Dénomination
                                    </Typography>
                                    <TextField placeholder="Dénomination"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        value={from.nomobjet}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    slug==="Badge" &&
                    <>
                        <SameTop>
                            <StatusHead slug={slugs[slug]} handleChangeCheked={null} chekedStatus={badge.etatbadge}/>
                        </SameTop>
                        <hr style={{ color: Styles.color.blue }}/>
                        <Grid container spacing={1}>
                            <Grid item xs>
                                <Grid container direction="row">
                                    <Typography style={ Styles.typography }>
                                        Dénomination *
                                    </Typography>
                                    <TextField placeholder="Dénomination"  sx={{ width: '100%' }}
                                        InputProps={{
                                            style: Styles.propsTextField
                                        }}
                                        value={badge.denomination}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
            </ShadowBox>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end" sx={{
                padding: "0 2.5rem 2.5rem 0"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: {
                        xs: "center",
                        md: "flex-end"
                    },
                    alignItems: "flex-end"
                }}>
                    <Box sx={{
                        marginRight: 2
                    }}>
                        <Link to={`/admin/${slug}`}>
                            <ComponentButton backgroundColor={ "#8D9496" } type={"button"}>Annuler</ComponentButton>
                        </Link>
                    </Box>
                    <Box>
                        <ComponentButton backgroundColor={ Styles.color.red }>Valider</ComponentButton>
                    </Box>
                </Box>
            </Grid>
        </form>
    )
}