import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

export const Styles = {
      boxModel: {
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: 4
      },
      color: {
            primary: "#F4F4F4",
            grey: "#9F9F9F",
            dark: "#010101",
            darkGrey: "#545454",
            defaultText: "#D9D9D9",
            inputRed: "#D9313F",
            blue: "#001F3E",
            red: "#D9313F"
      },
      fontTitle: {
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 700,
            lineHeight: "1.5rem",
            letterSpacing: "0.5px",
            textAlign: "left"
      },
      typography: {
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "1.5rem",
            letterSpacing: "0.5px",
            textAlign: "left",
            margin: "0.938rem 0"
      },
      inputDefaultTypo: {
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 500,
            letterSpacing: "0.5px",
            textAlign: "left"
      },
      fontTable: {
            fontFamily: "Montserrat",
            fontSize: "0.875rem",
            fontWeight: 500
      },
      fontDefaultButton: {
            fontFamily: "Montserrat",
            fontSize: "1.313rem",
            fontWeight: 600,
            letterSpacing: "0.5px",
            lineHeight: "2.5rem",
            textTransform: 'capitalize',
            textAlign: "center"
      },
      Link: {
            textDecoration: "none",
            color: "#D9D9D9"
      },
      buttonHover: {
            "&:hover": {
                  opacity: 0.5
            }
      },
      propsTextField: {
            height: "2.5rem",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: "1.5rem",
            letterSpacing: "0.5px",
            "& .MuiOutlinedInput-input.Mui-disabled": {
            WebkitTextFillColor: "#000000",
            opacity: 1,
            },
      },
      tableHeaderFont: {
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 700,
            lineHeight: "1.5",
            letterSpacing: "0.5px"
      },
      formControlLabel: {
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: 700,
            lineHeight: "1.5rem",
            letterSpacing: "0.5px"
      },
      paginationFont: {
            fontFamily: "Montserrat",
            fontSize: "1.125rem",
            color: "#8F8F8F",
            fontWeight: 400,
            lineHeight: "1.5rem",
            letterSpacing: "0.5px"
      },
      modals: {
            position: "absolute",
            backgroundColor: "#fff",
            width: 420,
            height: 230,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "auto",
            borderRadius: 1.5,
            padding: "1.5rem",
            "&:focus-visible": {
                  outline: "none"
            }
      }
}

export const StyledTableCell = styled(TableCell)(() => ({
      [`&.${tableCellClasses.head}`]: {
            backgroundColor:  "#fff",
            color: Styles.color.darkGrey,
            padding: 8,
            borderBottom: "2px solid #ACACAC"
      },
      [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none"
      }
}));


export const StyledTableRow = styled(TableRow)(() => ({
      '&:nth-of-type(even)': {
            backgroundColor: "#94949433"
      },
      '&:last-child td, &:last-child th': {
            border: 0
      }
}));