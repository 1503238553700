import { 
    Grid, 
    Box, 
    RadioGroup, 
    Radio, 
    FormControlLabel, 
    Typography
} from "@mui/material";
import { Styles } from "../styles/style";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const StatusHead = ({ slug, handleChangeCheked, chekedStatus })=> {
    
    return(
        <>
            <Grid item sx={Styles.fontTitle}>
                { slug }
            </Grid>
            <Grid item>
                <Box sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    {
                        slug==="Objets" &&
                        <Link to="/story/object">
                            <Box sx={{
                                marginRight: 2
                            }}>
                                <Button variant="contained" style={{
                                    backgroundColor: "#8D9496"
                                }} >
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <img src={"/assets/icons/story.png"} alt="Historique" style={{
                                            display: "block",
                                            marginRight: 3
                                        }} />
                                        <Typography sx={{
                                            textTransform: 'capitalize'
                                        }} style={Styles.fontTitle}>
                                            Historique
                                        </Typography>
                                    </Box>
                                </Button>
                            </Box>
                        </Link>
                    }
                    <Box sx={{
                        marginRight: 2,
                    }} style={Styles.formControlLabel}>
                        { slug === "Badges" ? "Etat de badge" : "Status" }
                    </Box>
                    <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        onChange={handleChangeCheked}
                    >
                        <FormControlLabel value="actif" sx={{
                            color: chekedStatus ? Styles.color.red  : ""
                        }} control={<Radio sx={{
                            color: chekedStatus ? `${Styles.color.red} !important` : ""
                        }} 
                            checked={chekedStatus}
                        />} label={<Typography style={Styles.formControlLabel}>
                            { slug === "Badges" ? "Pas encore utilisé" : "actif" }
                        </Typography>}/>
                        <FormControlLabel value="inactif" sx={{
                            color: !chekedStatus ? Styles.color.red  : ""
                        }} control={<Radio sx={{
                            color: !chekedStatus ? `${Styles.color.red} !important` : ""
                        }} 
                            checked={!chekedStatus}
                        />} label={<Typography style={Styles.formControlLabel}>
                            { slug === "Badges" ? "En cours d'utilisation" : "inactif" }
                        </Typography>} />
                    </RadioGroup>
                </Box>
            </Grid>
        </>
    )
}