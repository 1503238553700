import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { Dashbord } from "./dashbord";
import { Admin } from "./adminList/index";
import { AddList } from "./adminList/add";
import { DeleteList } from "./adminList/delete";
import { StoryObject } from "./objectStory";
import { Rapport } from "./rapport";
import { Setting } from "./setting";
import { SignInComponent } from "../authentication/login";
import { Box, Grid } from "@mui/material";
import { Navbar } from "../components/Navbar";
import { NavbarAdmin } from "../components/NavbarAdmin";
import { Header } from "../components/Header";
import AuthProvider from "react-auth-kit/AuthProvider";
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import { store } from "../actions/store";
import { VisitorProvider } from "../actions/visitorContext";
import users from "../actions/users";
import { AddClient } from "./adminSuper/addClient";
import { Clients } from "./adminSuper/clients";
import { Profil } from "./profil";
import { SetClient } from "./setClient";
import { Badge } from "./badge";

export const MainContent  = ()=> {
    const [size, setSize] = useState(window.innerWidth);
    const [isSuperAdmin, setIsSuperAdmin] = useState([]);
    const [isAdmin, setIsAdmin] = useState([]);
    const [isUser, setIsUser] = useState([]);
    const [openFullMenu, setOpenFullMenu] = useState(size >= 1366);
    const [anchorElAdmin, setAnchorElAdmin] = useState(null);
    const openAdmin = Boolean(anchorElAdmin);
    const [openSetting, setOpenSetting] = useState(false);
    const [active, setActive] = useState();
    const [adminActive, setAdminActive] = useState();
    const [settingActive, setSettingActive] = useState();
    const [modalForm, setModalForm] = useState(false);
    const [onSearch, setOnsearch] = useState("");
    const [badgeOnSearch, setOnSearch] = useState(false);
    const [timoutList, setTimoutList] = useState(0);
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';

    const handleOpenForm = ()=> {
        setModalForm(true);
        setOnSearch(false);
        setTimoutList(0);
    }

    const handleOpenAdmin = (ev)=> {
        setAnchorElAdmin(ev.currentTarget);
    }
    const handleCloseAdmin = ()=> {
        setAnchorElAdmin(null);
    }
    const handleOpenSetting = ()=> {
        setOpenSetting(true);
    }
    const handleCloseSetting = ()=> {
        setOpenSetting(false);
    }
    const handleClickFullMenu = ()=> {
        setOpenFullMenu(!openFullMenu);
    }

    const handleClickNav = ()=> {
        if (size >= 1366) return;
        setOpenFullMenu(false);
    }

    const handleActiveChange = (e)=> {
        setActive(+e.target.closest('.MuiButtonBase-root').dataset.active);
        handleCloseAdmin();
        handleCloseSetting();
        setAdminActive();
        setSettingActive();
    }

    const handleadminActiveChange = (e)=> {
        setAdminActive(+e.target.closest('.MuiButtonBase-root').dataset.active);
    }

    const handleSettingActiveChange = (e)=> {
        setSettingActive(+e.target.closest('.MuiButtonBase-root').dataset.active);
    }

    (function useWindowSize() {
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
                setOpenFullMenu(Boolean(window.innerWidth >= 1366));
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
    }());
    window.onclick = (e)=> {
        setOnsearch("");
        if (size >= 1366) return;
        setOpenFullMenu(e.target.closest('.menuList') ? true : false);
    }
    
    useEffect(() => {
        const callIsAdminFunc = async ()=> {
            try {
                const { email } = store.tokenObject.authValue.userState || {};
                const { data: { roles } } = email 
                ? 
                    await users.getUserByEmail(email) 
                : 
                    store.tokenObject.authValue.userState || {};

                roles.map(role => {
                    if (role === "ROLE_SUPER_ADMIN")
                        setIsSuperAdmin(["ROLE_SUPER_ADMIN"]);
                    if (role === "ROLE_ADMIN")
                        setIsAdmin(["ROLE_ADMIN"]);
                    if (role === "ROLE_USER")
                        setIsUser(["ROLE_USER"]);
                    return [];
                })
            } catch (err) {
                throw err;
            }
        }
        store.tokenObject.authValue.isSignIn && callIsAdminFunc();
    }, [])
    return(
        <Grid container spacing={0}>
            <RequireAuth fallbackPath="/login">
                {
                    (
                        isSuperAdmin.length !== 0 && openFullMenu &&
                        <NavbarAdmin 
                            size={size}
                            setOpenFullMenu={setOpenFullMenu}
                        />
                    )
                    ||
                    (
                        (isAdmin.length !== 0 || isUser.length !== 0) && openFullMenu &&
                        <Navbar 
                            openAdmin={openAdmin}
                            openSetting={openSetting}
                            handleOpenAdmin={handleOpenAdmin} 
                            handleCloseAdmin={handleCloseAdmin}
                            handleOpenSetting={handleOpenSetting}
                            handleCloseSetting={handleCloseSetting}
                            active={active}
                            handleClickNav={handleClickNav}
                            settingActive={settingActive}
                            adminActive={adminActive}
                            handleadminActiveChange={handleadminActiveChange}
                            handleSettingActiveChange={handleSettingActiveChange}
                            handleActiveChange={handleActiveChange}
                            isAdmin={isAdmin}
                            setOnsearch={setOnsearch}
                            setModalForm={setModalForm}
                        />
                    )
                }
            </RequireAuth>
            {!isLoginPage && (
                <Grid 
                    item
                    sx={{
                        backgroundColor: "#F6F6F6",
                        width: openFullMenu ? 267 : 0
                    }} 
                ></Grid>
            )}
            <Grid 
                item 
                xs 
                sx={{
                    backgroundColor: "#F6F6F6",
                    minHeight: "100vh",
                    backgroundImage: isLoginPage && "url('/assets/font-login.png')", 
                    backgroundSize: isLoginPage && "cover", 
                    backgroundPosition: isLoginPage && "center", 
                }}
            >
                <RequireAuth fallbackPath="/login">
                    <VisitorProvider>
                    {
                        (
                            isSuperAdmin.length !== 0 &&
                            <>
                                <Header 
                                    openFullMenu={openFullMenu} 
                                    handleClickFullMenu={handleClickFullMenu} 
                                />
                                <Box sx={{
                                    marginTop: 12
                                }}>
                                    <Routes>
                                        <Route exact path="/" element={<Clients openFullMenu={openFullMenu} />} />
                                        <Route path="/clients" element={<AddClient />} />
                                    </Routes>
                                </Box>
                            </>
                        )
                        ||
                        (
                            (isAdmin.length !== 0 || isUser.length !== 0) &&
                            <>
                                <Header 
                                    openFullMenu={openFullMenu} 
                                    handleClickFullMenu={handleClickFullMenu} 
                                    setOpenSetting={setOpenSetting}
                                    setActive={setActive}
                                    setModalForm={setModalForm}
                                    onSearch={onSearch}
                                    setOnsearch={setOnsearch}
                                    handleOpenForm={handleOpenForm}
                                />
                                <Box sx={{
                                    marginTop: 12
                                }}>
                                    <Routes>
                                        <Route exact path="/" element={
                                                <Dashbord 
                                                    openFullMenu={openFullMenu} 
                                                    modalForm={modalForm}
                                                    setModalForm={setModalForm}
                                                    badgeOnSearch={badgeOnSearch}
                                                    setOnSearch={setOnSearch}
                                                    timoutList={timoutList}
                                                    setTimoutList={setTimoutList}
                                                />
                                            } 
                                        />
                                        <Route path="/admin/:slug" element={
                                                <Admin openFullMenu={openFullMenu} />
                                            } 
                                        />
                                        <Route path="/add/:slug" element={<AddList />} />
                                        <Route path="/delete/:slug" element={<DeleteList />} />
                                        <Route path="/story/object" element={<StoryObject />} />
                                        <Route path="/rapport" element={
                                                <Rapport 
                                                    openFullMenu={openFullMenu} 
                                                />
                                            } 
                                        />
                                        {
                                            isAdmin.length !== 0 &&
                                            <>
                                                <Route path="/setting/users" element={<Setting />} />
                                                <Route path="/setting/profils" element={<Profil />} />
                                                <Route path="/setting/badges" element={<Badge />} />
                                                <Route path="/clients" element={<SetClient />} />
                                            </>
                                        }
                                    </Routes>
                                </Box>
                            </>
                        )
                    }
                    </VisitorProvider>
                </RequireAuth>
                <Routes>
                    <Route path="/login" element={<SignInComponent /> } />
                </Routes>
            </Grid>
        </Grid>
    )
}

export const Index = () => {
    return (
        <AuthProvider store={store}>
            <BrowserRouter>
                <MainContent />
            </BrowserRouter>
        </AuthProvider>
    );
};