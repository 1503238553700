import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { Styles } from "../styles/style";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import uuid from "react-uuid";
import { dateFormat, HoursFormat } from "../actions/dateFormated";
import { LoadingData, NullData } from "./Loading";
import { StyledTableCell, StyledTableRow } from "../styles/style";
import { ModalView } from "./ShowModal";

const cellTableHead = (handleHeaderOrder, content, key) => {
  return (
    <StyledTableCell key={key}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: Styles.color.darkGrey,
          padding: 1,
          cursor: "pointer",
        }}
        onClick={() => handleHeaderOrder(key)}
      >
        <Box
          sx={{
            marginRight: 1,
            textTransform: "capitalize",
          }}
          style={Styles.tableHeaderFont}
        >
          {content}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ExpandLess fontSize="15px" />
          <ExpandMore fontSize="15px" />
        </Box>
      </Box>
    </StyledTableCell>
  );
};

export default function RapportTable({
  tableRef,
  openFullMenu,
  dataHeader,
  isOnload,
  dataBody,
  menu,
  handleHeaderOrder,
}) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const handleOpen = (data) => {
    setData(data);
    setOpen(true);
  };
  const handleclose = () => {
    setOpen(false);
  };

  const maxWidth =
    openFullMenu && window.innerWidth > 1200
      ? window.innerWidth - 420
      : window.innerWidth;
 
  return (
    <Box
      style={{
        maxWidth: `${maxWidth}px`,
      }}
    >
      <TableContainer
        component={Paper}
        ref={tableRef}
        sx={{
          boxShadow: "none",
        }}
      >
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {dataHeader.map((content, key) =>
                cellTableHead(handleHeaderOrder, content, key)
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {isOnload ? (
              <LoadingData length={dataHeader.length} />
            ) : dataBody.length === 0 ? (
              <NullData length={dataHeader.length} right={"10%"} />
            ) : (
              dataBody.map((data) => (
                <StyledTableRow
                  key={uuid()}
                  title="Voir détails"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleOpen(data)}
                >
                  {menu[0].status && (
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={Styles.fontTable}
                    >
                      {data?.numvisiteur || "...."}
                    </StyledTableCell>
                  )}
                  {menu[1].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {(data?.visiteur?.nom || "....") +
                        " " +
                        (data?.visiteur?.prenom || "")}
                    </StyledTableCell>
                  )}
                  {menu[2].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {data?.visiteur?.societe?.denomination || "...."}
                    </StyledTableCell>
                  )}
                  {menu[3].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {data?.visiteur?.categorieVisiteur?.libelle || "...."}
                    </StyledTableCell>
                  )}
                  {menu[4].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {(data?.personnelVisite?.nom || "....") +
                        " " +
                        (data?.personnelVisite?.prenom || "")}
                    </StyledTableCell>
                  )}
                  {menu[5].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {dateFormat(data?.dateEntre) || "...."}
                    </StyledTableCell>
                  )}
                  {menu[6].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {HoursFormat(data?.dateEntre) || "...."}
                    </StyledTableCell>
                  )}
                  {menu[7].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {dateFormat(data?.dateSortie) || "...."}
                    </StyledTableCell>
                  )}
                  {menu[8].status && (
                    <StyledTableCell style={Styles.fontTable}>
                      {HoursFormat(data?.dateSortie) || "...."}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalView data={data} open={open} handleclose={handleclose} />
    </Box>
  );
}
