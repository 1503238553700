import React, { useEffect, useState } from "react";
import { ShadowBox } from "../components/BoxShadow";
import { Styles } from "../styles/style";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import etatBadge from "../actions/etatBadge";

export const Badge = () => {
  const [badge, setBadge] = useState({ id: null, etat: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await etatBadge.getEtatBadge();
      if (data && data[0]?.idetatbadge) {
        setBadge({
          id: data[0]?.idetatbadge,
          etat: data[0]?.denomination || "Oui",
        });
      }
      setIsLoading(false);
    };
    getData();
  }, []);

  const handleBadgeOptionChange = async (event) => {
    setIsLoading(true);
    const etat = event.target.value;
    try {
      const response = await etatBadge.editEtatBadge(badge.id, etat);

      if (response.status === 200) {
        setBadge({
          id: badge.id,
          etat: etat,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ShadowBox>
        <Grid
          container
          rowSpacing={1}
          justifyContent={{
            xs: "center",
            md: "space-between",
          }}
          alignItems="center"
          sx={{
            minHeight: 80,
          }}
        >
          <Grid
            item
            sx={Styles.fontTitle}
            style={{
              color: Styles.color.blue,
            }}
          >
            Gestion des badges
          </Grid>
        </Grid>
        <hr style={{ color: Styles.color.blue, marginBottom: 20 }} />
        <Grid container spacing={0}>
          <RadioGroup
            row
            name="badge-management-options"
            value={badge.etat}
            onChange={handleBadgeOptionChange}
          >
            <FormControlLabel
              value="Oui"
              sx={{ marginRight: 5 }}
              control={
                <Radio
                  disabled={isLoading}
                  style={{
                    color: badge.etat === "Oui" ? Styles.color.red : "",
                  }}
                />
              }
              label={
                <Typography
                  sx={Styles.formControlLabel}
                  style={{
                    color: badge.etat === "Oui" ? Styles.color.red : "",
                  }}
                >
                  Oui
                </Typography>
              }
            />
            <FormControlLabel
              value="Non"
              sx={{ marginRight: 5 }}
              control={
                <Radio
                  disabled={isLoading}
                  style={{
                    color: badge.etat === "Non" ? Styles.color.red : "",
                  }}
                />
              }
              label={
                <Typography
                  sx={Styles.formControlLabel}
                  style={{
                    color: badge.etat === "Non" ? Styles.color.red : "",
                  }}
                >
                  Non
                </Typography>
              }
            />
            <FormControlLabel
              value="Libre"
              control={
                <Radio
                  disabled={isLoading}
                  style={{
                    color: badge.etat === "Libre" ? Styles.color.red : "",
                  }}
                />
              }
              label={
                <Typography
                  sx={Styles.formControlLabel}
                  style={{
                    color: badge.etat === "Libre" ? Styles.color.red : "",
                  }}
                >
                  Libre
                </Typography>
              }
            />
          </RadioGroup>
        </Grid>
      </ShadowBox>
    </>
  );
};
