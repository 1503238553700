export const adminData = [
    {
        id: 0,
        title: "Visiteurs",
        link: "Visitor",
        img: "/assets/icons-temp/visitor.png"
    },
    {
        id: 1,
        title: "Sociétés",
        link: "Enterprise",
        img: "/assets/icons-temp/enterprise.png"
    },
    {
        id: 2,
        title: "Catégories",
        link: "Category",
        img: "/assets/icons-temp/category.png"
    },
    {
        id: 3,
        title: "Visités",
        link: "Visited",
        img: "/assets/icons-temp/supervisor.png"
    },
    {
        id: 4,
        title: "Objets",
        link: "Object",
        img: "/assets/icons-temp/objects.png"
    },
    {
        id: 5,
        title: "Badges",
        link: "Badge",
        img: "/assets/icons-temp/badge.png"
    }
];

export const slugs = {
    Visitor: "Visiteurs",
    Enterprise: "Sociétés",
    Category: "Catégories",
    Visited: "Visités",
    Object: "Objets",
    Badge: "Badges"
}

export const DashBoardDataTable = [
    "Bagde", 
    "Nom", 
    "CIN",
    "Société", 
    "Visité", 
    "Entrée", 
    "Sortie", 
    "Objets"
];

export const adminDataTable = [
    [
        "Nom", 
        "Prénom", 
        "Société", 
        "Email", 
        "Tél", 
        "N°CIN", 
        "Catégorie"
    ],
    ["Dénomination"],
    ["Dénomination"],
    ["Nom", "Prénom", "Email", "Tél"],
    ["Dénomination"],
    ["Dénomination", "Etat badge"]
];

export const rapportTable = [
    "Badge", 
    "Visiteur", 
    "Société",
    "Catégorie", 
    "Visité", 
    "Date Entrée", 
    "Heure Entrée", 
    "Date sortie", 
    "Heure sortie"
];

export const objectStory = [
    "Nom", 
    "Prénom", 
    "Société", 
    "Objets", 
    "Date Remise", 
    "Heure Remise", 
    "Date Retour", 
    "Heure Retour"
];


export const filters = [
    "Société", 
    "Catégorie", 
]

export const selectItems = [
    "Visiteur", 
    "Société",
    "Catégorie", 
    "Visité"
]

export const usersTable = [
    "Nom",
    "Prénom",
    "Email",
    "Téléphone",
    "Matricule",
    "Rôle"
]